import Quill from "quill";
import { ImageDrop } from "quill-image-drop-module";
import "quill/dist/quill.bubble.css"; // for bubble theme
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import Vue from "vue";
import VueClipboard from 'vue-clipboard2';
import VueQuillEditor from "vue-quill-editor";
import VueRouter from "vue-router";
import DatetimePicker from "vuetify-datetime-picker";
import Vuex from "vuex";
import App from "./App.vue";
import config from "./config.js";
import Aman from "./jwaman/Aman.vue";
import Dashboard from "./jwaman/Dashboard.vue";
import jwa from "./jwaman/jw.aman.js";
import Login from "./jwaman/Login.vue";
import Panel from "./jwaman/Panel.vue";
import PasswordModify from "./jwaman/PasswordModify.vue";
import jw from "./jwapp/jw.js";
import vuetify from "./plugins/vuetify";

var app = {};

app.Start = function (mode) {
    const pages = {
        login: "/login",
        passmod: "/passmod",
        aman: "/aman",
    };

    Vue.use(VueRouter);
    const router = new VueRouter({
        routes: [
            {
                path: pages.login,
                component: Login,
            },
            {
                path: pages.passmod,
                component: PasswordModify,
            },
            {
                path: pages.aman,
                component: Aman,
                children: [
                    {
                        path: "panel",
                        component: Panel,
                    },
                    {
                        path: "dashboard",
                        component: Dashboard,
                    },
                ],
            },
        ],
    });

    const Config = config.Get(mode);

    Vue.use(Vuex);
    const store = new Vuex.Store({
        state: {
            client: jw.net.Client(Config.Url, {
                loginFirstHandler: function () {
                    router.replace(jwa.utils.RouterPath(router, pages.login));
                },
                router: router,
            }),
            lang: "",
            pages: pages,
        },
        mutations: {
            setClientDialog(state, dialog) {
                state.client.SetDialog(dialog);
            },
            setClientLoading(state, loading) {
                state.client.SetLoading(loading.showLoading, loading.hideLoading);
            },
            setLang(state, lang) {
                localStorage.setItem("lang", lang);
            },
        },
        getters: {
            lang() {
                return localStorage.getItem("lang");
            },
        },
    });

    VueClipboard.config.autoSetContainer = true;
    Vue.use(VueClipboard);

    Vue.use(DatetimePicker);

    Quill.register("modules/imageDrop", ImageDrop);

    // const Clipboard = Quill.import('modules/clipboard')
    // // const Delta = Quill.import('delta')

    // class VuePlainClipboard extends Clipboard {
    //   onPaste(e) {
    //     // e.preventDefault()
    //     // const range = this.quill.getSelection()
    //     // var text = e.clipboardData.getData('text/plain')
    //     console.error(e.clipboardData.files)
    //     // text = text.replace("src=\"file:///C:/Users/ddeyes/AppData/Local/Temp/msohtmlclip1/01/clip_image002.jpg\"", "")
    //     // const delta = new Delta().retain(range.index).delete(range.length).insert(text)
    //     // const index = text.length + range.index
    //     // const length = 0
    //     // this.quill.updateContents(delta, 'silent')
    //     // this.quill.setSelection(index, length, 'silent')
    //     // this.quill.scrollIntoView()

    //     // const image = e.clipboardData.getData('img')
    //   }
    // }
    // Quill.register('modules/clipboard', VuePlainClipboard, true)

    // https://www.npmjs.com/package/vue-quill-editor
    Vue.use(
        VueQuillEditor,
        {
            modules: {
                imageDrop: true,
                toolbar: {
                    container: [
                        ["bold", "italic", "underline", "strike"],
                        [
                            {
                                header: 1,
                            },
                            {
                                header: 2,
                            },
                        ],
                        [
                            {
                                indent: "-1",
                            },
                            {
                                indent: "+1",
                            },
                        ],
                        [
                            {
                                size: ["small", false, "large", "huge"],
                            },
                        ],
                        [
                            {
                                header: [1, 2, false],
                            },
                        ],
                        [
                            {
                                color: [],
                            },
                            {
                                background: [],
                            },
                        ],
                        [
                            {
                                align: [],
                            },
                        ],
                        ["clean"],
                        ["link", "image"],
                    ],
                },
            },
            placeholder: "请在此编辑内容",
        }
    );

    Vue.config.productionTip = false;

    new Vue({
        store,
        router,
        vuetify,
        render: (h) => h(App),
    }).$mount("#app");
};

export default app;
