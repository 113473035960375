import axios from "axios";
import json from "./jw.json.js";
// const wxx = require('./jw.wxx.js');
import time from "./jw.time.js";
// const utils = require('./jw.utils.js');

const net = {};

const http = {};

http.StatusCode = {
  Ok: 200,
  Intercepted: 1001, // 自定义
};

http.HeaderKey = {
  Ecology: "Jwhd-Ecology",
  Platform: "Jwhd-Platform",
  System: "Jwhd-System",
  Version: "Jwhd-Version",
  Env: "Jwhd-Env",
  TimezoneOffset: "Jwhd-Timezoneoffset",
  Token: "Jwhd-Token",
  AccessToken: "Jwhd-Access-Token",
};

http.HeaderKeyWX = {
  EnvVersion: "Jwhdwx-Env-Version",
  SubscribeMessageMiniprogramState: "Jwhdwx-Submsgprostate",
};

http.HeaderEcology = {
  Weixin: "weixin",
  Web: "web",
};

net.Code = {
  LoginFirst: 2001,
  RegisterFirst: 2002,
  LoginWXMissingCode: 2101,
  AuthFirst: 2011,
  AuthFirstWXUserInfo: 2011,
  AuthFirstWXPhone: 2012,

  HintStart: -20,
  HintEnd: -10,
  Remind: -10,
  JumpIn: -11, // 跳转到app内的某个页面
  JumpOut: -12, // 跳转到指定链接
  JumpBack: -13, // 回到上一个页面
  CallApi: -14, // 调用一个带有参数的接口

  RequestConfirm: -100,
  RequireUpgrade: -200,
  RequireWXSubscribeMessage: -1001,
};

net.NotifyCode = {
  Remind: 0,
  JumpIn: 1, // 跳转到app内的某个页面
  JumpOut: 2, // 跳转到指定链接
  JumpBack: 3, // 回到上一个页面
  JumpImage: 4, // 跳转到一个简单显示图片的页面,
  CallApi: 5, // 调用一个带有参数的接口
  DownloadSave: 101, // 下载并保存
  Share: 201, // 分享
  SetClipboard: 301, // 复制到剪切板
  PhoneCall: 302, // 打电话
  Custom: 1000, // 自定义
};
net.NotifyShow = {
  NoConfirm: -1, // 无需确认直接跳转
  Modal: 0, // 系统模态对话框
  MaskModal: 1, // 蒙版模态对话框
};

net.DoErrorHint = function (hint, params) {
  if (!hint) {
    return;
  }
  if (!params) {
    params = {};
  }
  var { client, success } = params;
  if (!client) {
    console.error("请提供client对象");
    return;
  }
  if (hint.Show < 0) {
    net.doErrorHint(hint, client, success);
    return;
  }
  var { dialog } = client;
  if (!dialog) {
    console.error("client中dialog对象未设置");
    return;
  }
  console.error("dialog");
  dialog.Show({
    title: hint.Title ? hint.Title : "",
    content: hint.Info ? hint.Info : "",
    showCancel: hint.ShowCancel ? true : false,
    confirmText: hint.ConfirmText ? hint.ConfirmText : "",
    cancelText: hint.CancelText ? hint.CancelText : "",
    success: function (res) {
      if (res.confirm) {
        net.doErrorHint(hint, {
          client: client,
          success: success,
        });
      }
    },
  });
  // wx.showModal({
  //     title: hint.Title ? hint.Title : '',
  //     content: hint.Info ? hint.Info : '',
  //     showCancel: hint.ShowCancel ? true : false,
  //     confirmText: hint.ConfirmText ? hint.ConfirmText : '',
  //     cancelText: hint.CancelText ? hint.CancelText : '',
  //     success: function(res) {
  //         if (res.confirm) {
  //             net.doErrorHint(hint, {
  //                 client: client,
  //                 success: success,
  //             });
  //         }
  //     }
  // });
};

net.doErrorHint = function (hint, { client, success }) {
  // if (!client) {
  //     client = getApp().Client;
  // }
  if (!hint) {
    return false;
  }
  switch (hint.Code) {
    case net.Code.Remind: {
      break;
    }
    case net.Code.JumpIn: {
      console.debug("jump in: ", hint.Url);
      // if (!hint.Url) {
      //     return false;
      // }
      // wxx.Navigate(hint.Navigate, {
      //     url: hint.Url,
      //     data: hint.Data,
      // });
      return true;
    }
    case net.Code.JumpOut: {
      console.debug("jump out: ", hint.Url);
      // if (!hint.Url) {
      //     return false;
      // }
      // if (!hint.Title) {
      //     hint.Title = '浏览器';
      // }
      // wxx.Navigate(hint.Navigate, {
      //     url: '/jwapp/jumpout/jumpout',
      //     data: {
      //         title: hint.Title,
      //         url: hint.Url,
      //         data: hint.Data,
      //     },
      // });
      return true;
    }
    case net.Code.JumpBack: {
      console.debug("jump back: ", hint.Url, hint.BackUrl);
      // wxx.NavigateBack({
      //     url: hint.BackUrl,
      //     to: hint.Url,
      // });
      return true;
    }
    case net.Code.CallApi: {
      client.Post(hint.Url, {
        data: {
          Key: hint.Data,
        },
        success: function () {
          if (success) {
            success();
          }
        },
        failed: function (err) {
          // wxx.ShowError(err.Info);
          console.error(err);
        },
      });
      return true;
    }
  }
  return false;
};

net.DoNotify = function (notify, params) {
  if (!params) {
    params = {};
  }
  var { client, onConfirm, onConfirmSuccess } = params;
  if (!notify) {
    return;
  }
  // 【兼容处理】兼容之前的写法
  var confirmOp = notify.ConfirmOp;
  if (!confirmOp) {
    confirmOp = notify;
  }
  if (notify.Show < 0) {
    // net.doNotify(notify, client, onConfirm);
    switch (confirmOp.Code) {
      case net.NotifyCode.Remind: {
        alert(notify.Info); // TODO 先这样实现
        return;
      }
    }
    net.doNotify(confirmOp, {
      client: client,
      onPreDo: onConfirm,
      success: onConfirmSuccess,
    });
    return;
  }
  // wx.showModal({
  //     title: notify.Title ? notify.Title : '',
  //     content: notify.Info ? notify.Info : '',
  //     showCancel: notify.ShowCancel ? true : false,
  //     confirmText: notify.ConfirmText ? notify.ConfirmText : '',
  //     cancelText: notify.CancelText ? notify.CancelText : '',
  //     success: function(res) {
  //         // if (res.confirm) {
  //         // 	net.doNotify(notify, client, onPreDo);
  //         // }
  //         if (res.confirm) {
  //             net.doNotify(confirmOp, {
  //                 client: client,
  //                 onPreDo: onConfirm,
  //                 success: onConfirmSuccess,
  //             });
  //         } else if (res.cancel) {
  //             net.doNotify(notify.CancelOp, {
  //                 client: client,
  //             });
  //         }
  //     }
  // });
};

net.doNotify = function (notify, { client, onPreDo, success }) {
  // if (!client) {
  //     client = getApp().Client;
  // }
  if (!notify) {
    return false;
  }
  if (notify.Key) {
    // wxx.SetObjectSync(notify.Key, notify.Data);
  }
  if (onPreDo) {
    onPreDo(notify);
  }
  switch (notify.Code) {
    case net.NotifyCode.Remind: {
      break;
    }
    case net.NotifyCode.JumpIn: {
      console.debug("jump in: ", notify.Url);
      // if (!notify.Url) {
      //     return false;
      // }
      // wxx.Navigate(notify.Navigate, {
      //     url: notify.Url,
      //     data: notify.Data,
      //     backRefresh: notify.BackRefresh,
      // });
      // if (success) {
      //     success();
      // }
      break;
    }
    case net.NotifyCode.JumpOut: {
      console.debug("jump out: ", notify.Url);
      // if (!notify.Url) {
      //     return false;
      // }
      // if (!notify.Title) {
      //     notify.Title = '浏览器';
      // }
      // wxx.NavigateTo({
      //     url: '/jwapp/jumpout/jumpout',
      //     data: {
      //         title: notify.Title,
      //         url: notify.Url,
      //         data: notify.Data,
      //     },
      // });
      // if (success) {
      //     success();
      // }
      break;
    }
    case net.NotifyCode.JumpBack: {
      console.debug("jump back: ", notify.Url, notify.BackUrl);
      // wxx.NavigateBack({
      //     url: notify.BackUrl,
      //     to: notify.Url,
      // });
      // if (success) {
      //     success();
      // }
      break;
    }
    case net.NotifyCode.JumpImage: {
      console.debug("jump image: ", notify.Url);
      // if (!notify.Url) {
      //     return false;
      // }
      // if (!notify.Title) {
      //     notify.Title = '小程序';
      // }
      // wxx.NavigateTo({
      //     url: '/jwapp/jump-image/jump-image',
      //     data: {
      //         title: notify.Title,
      //         url: notify.Url,
      //         data: notify.Data,
      //     },
      // });
      // if (success) {
      //     success();
      // }
      break;
    }
    case net.NotifyCode.CallApi: {
      // var token = wxx.GetToken();
      client.Post(notify.Url, {
        data: {
          Key: notify.Data,
          // Token: token,
        },
        success: function (res, ext) {
          if (success) {
            success(res, ext);
          }
        },
        failed: function (err) {
          // wxx.ShowError(err.Info);
          console.error(err);
        },
      });
      break;
    }
    case net.NotifyCode.DownloadSave: {
      var a = document.createElement("a");
      a.href = notify.Url;
      a.click();
      break;
    }
  }
  return true;
};

net.RemoteFileOp = {
  None: 0,
  Insert: 1,
  Update: 2,
  Delete: 3,
};

net.GetToken = function (tokenKey) {
  // return wxx.GetStorageSync('jw_token');
  var key = "jw_token";
  if (tokenKey) {
    key += "_" + tokenKey;
  }
  // console.error("key:", key);
  return localStorage.getItem(key);
};
net.SetToken = function (token, tokenKey) {
  var key = "jw_token";
  if (tokenKey) {
    key += "_" + tokenKey;
  }
  // console.error("setKey:", key);
  // wxx.SetStorageSync('jw_token', token);
  localStorage.setItem(key, token);
};
net.GetAccessToken = function () {
  // return wxx.GetStorageSync('jw_access_token');
  return localStorage.jw_access_token;
};
net.SetAccessToken = function (token) {
  // wxx.SetStorageSync('jw_access_token', token);
  localStorage.jw_access_token = token;
};


net.Client = function (url, params) {
  var self = {};
  self.axios = axios.create({
    baseURL: url,
    timeout: 180000,
  });

  self.url = url;

  self.version = "";
  self.env = "";
  self.platform = "";
  self.loginFirstHandler = null;
  self.registerFirstHandler = null;
  self.authFirstHandler = null;
  self.router = null;
  if (params) {
    self.version = params.version;
    self.env = params.env;
    self.platform = params.platform;
    self.loginFirstHandler = params.loginFirstHandler;
    self.registerFirstHandler = params.registerFirstHandler;
    self.authFirstHandler = params.authFirstHandler;
    self.router = params.router;
  }
  // self.skipSubscribeMessage = params.skipSubscribeMessage;
  // self.setLookBack = params.setLookBack;

  self.SetDialog = function (dialog) {
    self.dialog = dialog;
  };
  self.SetLoading = function (showLoading, hideLoading) {
    self.showLoading = showLoading;
    self.hideLoading = hideLoading;
  };

  self.getFullUrl = function (pattern) {
    return self.url + (pattern ? "/" + pattern : "/");
  };

  self.Post = function (pattern, params) {
    return new Promise((resolve, reject) => {
      if (!params) {
        params = {};
      }
      params.success = function (res, ext) {
        resolve(res, ext);
      };
      params.failed = function (err) {
        reject(err);
      };
      self.doPost(pattern, params);
    });
  };

  self.doPost = function (pattern, params) {
    var data;
    var header;
    var responseType;
    var ignoreLoading = false;
    var success = null;
    var failed = null;
    var doNotify;
    var lang;

    if (params) {
      data = params.data;
      header = params.header;
      responseType = params.responseType;
      ignoreLoading = params.ignoreLoading;
      // var loadingTitle = params.loadingTitle;
      // if (!ignoreLoading) {
      //     if (!loadingTitle) {
      //         loadingTitle = '加载中';
      //     }
      //     wx.showLoading({
      //         title: loadingTitle,
      //         mask: true,
      //     });
      // }
      success = params.success;
      failed = params.failed;
      doNotify = params.notify;

      // 语言处理
      var p = self.patternByLang(pattern);
      pattern = p.pattern;
      lang = p.lang;
    }
    var url = self.getFullUrl(pattern);

    console.debug("发送：" + url);
    console.debug(data);

    header = self.handleHeadersOnReq(header, lang);

    if (!ignoreLoading && self.showLoading) {
      var loadingTitle = params.loadingTitle;
      if (!loadingTitle) {
        loadingTitle = "加载中";
      }
      var pendingShowLoadingTimeout = setTimeout(() => {
        self.showLoading(loadingTitle);
      }, 5000);
    }

    // console.trace();
    self.axios
      .post(url, data, {
        headers: header,
        responseType: responseType ? responseType : "json",
      })
      .then(function (res) {
        var resHeader = res.headers;
        self.handleHeadersOnRes(resHeader, lang);

        var resData = res.data;
        res.ok = res.status == http.StatusCode.Ok;

        console.debug("来自：" + url);
        console.debug(res);
        // 先结束loading，避免success再次调用post时，loading不能正确关闭
        if (!ignoreLoading && self.hideLoading) {
          clearTimeout(pendingShowLoadingTimeout);
          self.hideLoading();
        }
        if (res.ok && resData) {
          var err = resData.Error;
          if (err) {
            console.error(err);
            if (err.Code) {
              switch (err.Code) {
                case net.Code.LoginFirst: {
                  // !self.loginFirstHandler || self.loginFirstHandler();
                  if (self.loginFirstHandler) {
                    self.loginFirstHandler(function () {
                      // 登录后重新调用api获取数据
                      self.doPost(pattern, params, ignoreLoading);
                    });
                  }
                  return;
                }
                case net.Code.RegisterFirst: {
                  // if (self.registerFirstHandler) {
                  //   wx.showModal({
                  //       title: '温馨提示',
                  //       content: err.Info,
                  //       confirmText: '去注册',
                  //       cancelText: '先等等',
                  //       success: function(res) {
                  //           if (res.confirm) {
                  //               self.registerFirstHandler();
                  //           }
                  //       }
                  //   });
                  // }
                  return;
                }
                // case net.Code.AuthFirst: {
                // 	!self.authFirstHandler || self.authFirstHandler();
                // 	return;
                // }
                // case net.Code.AuthFirstWXUserInfo:
                // case net.Code.AuthFirstWXPhone: {
                //     !self.authFirstHandler || self.authFirstHandler(err);
                //     return;
                // }
                case net.Code.RequestConfirm: {
                  // wx.showModal({
                  //     title: err.Title,
                  //     content: err.Info,
                  //     confirmText: err.ConfirmText,
                  //     cancelText: err.CancelText,
                  //     success: function(res) {
                  //         if (res.confirm) {
                  //             data.Confirmed = true;
                  //             params.data = data;
                  //             self.Post(pattern, params);
                  //         }
                  //     },
                  // });
                  self.dialog.Show({
                    title: err.Title,
                    content: err.Info,
                    confirmText: err.ConfirmText,
                    cancelText: err.CancelText,
                    success: function (res) {
                      if (res.confirm) {
                        data.Confirmed = true;
                        params.data = data;
                        self.doPost(pattern, params);
                      }
                    },
                  });
                  return;
                }
                case net.Code.RequireUpgrade: {
                  self.doPost(err.Url, {
                    loadingTitle: err.Title,
                    data: err.Data,
                    success: function () {
                      self.doPost(pattern, params);
                    },
                  });
                  return;
                }
                // case net.Code.RequireWXSubscribeMessage: {
                //     var tmplIds = err.Data;
                //     wx.getSetting({
                //         withSubscriptions: true,
                //         success: function(res) {
                //             var needRequestSubscribeMessage = false;
                //             if (res.subscriptionsSetting) {
                //                 var itemSettings = res.subscriptionsSetting.itemSettings;
                //                 if (!itemSettings) {
                //                     needRequestSubscribeMessage = true;
                //                 } else {
                //                     utils.ArrayForeach(tmplIds, function(tmplId) {
                //                         var itemSetting = itemSettings[tmplId];
                //                         if (!itemSetting || itemSetting != "accept") {
                //                             needRequestSubscribeMessage = true;
                //                         }
                //                     });
                //                 }
                //             } else {
                //                 needRequestSubscribeMessage = true;
                //             }
                //             if (needRequestSubscribeMessage) {
                //                 wx.requestSubscribeMessage({
                //                     tmplIds: err.Data,
                //                     success: function(res) {
                //                         data.Accepted = true;
                //                         params.data = data;
                //                         self.Post(pattern, params);
                //                     },
                //                     failed: function(res) {
                //                         jw.wxx.ShowError("授权失败：" + jw.json.Stringify(res));
                //                     },
                //                 });
                //             } else {
                //                 data.Accepted = true;
                //                 params.data = data;
                //                 self.Post(pattern, params);
                //             }
                //         },
                //         fail: function(res) {
                //             jw.wxx.ShowError(jw.json.Stringify(res));
                //         },
                //     });

                //     return;
                // }
              }
              if (
                net.Code.HintStart <= err.Code &&
                err.Code <= net.Code.HintEnd
              ) {
                self.defaultDoErrorHint(err);
                return;
              }
            }
            if (failed) {
              failed(err);
            }
          } else {
            if (success) {
              success(resData.Result, resData.Ext);
            }

            var notify = resData.Notify;
            if (notify) {
              if (doNotify) {
                doNotify(notify);
              } else {
                self.defaultDoNotify(notify);
              }
            }
          }
        } else {
          if (res.statusCode == http.StatusCode.Intercepted) {
            console.warn("请求被拦截");
            return;
          }
          if (!resData) {
            resData = "网络错误" + JSON.stringify(res);
          }
          console.error(res);
          console.trace();
          if (failed) {
            failed({
              Info: resData,
            });
          }
        }
      })
      .catch(function (err) {
        console.error(err);
        // 先结束loading，避免success再次调用post时，loading不能正确关闭
        if (!ignoreLoading && self.hideLoading) {
          clearTimeout(pendingShowLoadingTimeout);
          self.hideLoading();
        }
        if (failed) {
          failed(err);
        }
      });
  };

  self.patternByLang = function (pattern) {
    var lang;
    var patternLang;
    if (self.router) {
      lang = self.router.currentRoute.query.lang;
    }
    if (pattern) {
      var slashIndex = pattern.lastIndexOf("/");
      if (slashIndex == 0) {
        var slashIndex2 = pattern.indexOf("/");
        if (slashIndex2 > 0) {
          patternLang = pattern.substr(slashIndex2);
        }
      } else if (slashIndex > 0) {
        patternLang = pattern.substr(slashIndex);
      }
    }
    if (lang && !patternLang) {
      pattern += "/" + lang;
    }

    return {
      pattern,
      lang,
    };
  };

  self.handleHeadersOnReq = function (header, tokenKey) {
    if (!header) {
      header = {};
    }
    header[http.HeaderKey.Ecology] = http.HeaderEcology.Web;
    if (self.platform) {
      header[http.HeaderKey.Platform] = self.platform;
    }
    var token = net.GetToken(tokenKey);
    if (token) {
      header[http.HeaderKey.Token] = token;
    }
    var accessToken = net.GetAccessToken();
    if (accessToken) {
      header[http.HeaderKey.AccessToken] = accessToken;
    }
    if (self.version) {
      header[http.HeaderKey.Version] = self.version;
    }
    if (self.env) {
      header[http.HeaderKey.Env] = self.env;
    }
    header[http.HeaderKey.TimezoneOffset] = time.GolangFixedZoneOffset();

    // if (!self.wxEnvVersion) {
    //     var accountInfo = wx.getAccountInfoSync();
    //     if (accountInfo) {
    //         self.wxEnvVersion = accountInfo.miniProgram.envVersion;
    //     }
    // }
    // if (self.wxEnvVersion) {
    //     header[http.HeaderKeyWX.EnvVersion] = self.wxEnvVersion;
    //     header[http.HeaderKeyWX.SubscribeMessageMiniprogramState] = wxx.SubscribeMessageMiniprogramStateFromEnvVersion(self.wxEnvVersion);
    // }
    return header;
  };

  self.handleHeadersOnRes = function (header, tokenKey) {
    if (!header) {
      return;
    }
    var token = header[http.HeaderKey.Token.toLowerCase()];
    if (token) {
      net.SetToken(token, tokenKey);
    }
  };

  self.defaultDoErrorHint = function (hint) {
    net.DoErrorHint(hint, {
      client: self,
    });
  };

  self.defaultDoNotify = function (notify) {
    net.DoNotify(notify, {
      client: self,
    });
  };

  self.UploadFile = function (
    pattern,
    { title, name, key, file, header, responseType, lang }
  ) {
    // var { success, failed, title, name, file, header, ignoreLoading } = params;
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve();
        return;
      }
      if (!title) {
        title = "";
      }
      header = self.handleHeadersOnReq(header, lang);
      if (!header) {
        header = {};
      }
      header["Content-Type"] = "multipart/form-data";

      var formData = new FormData();
      formData.append(name, file);

      var url = self.getFullUrl(pattern);
      console.debug("上传至：" + url);
      console.debug(file);
      self.axios
        .post(url, formData, {
          headers: header,
          responseType: responseType ? responseType : "json",
          timeout: 0,
        })
        .then(function (res) {
          var resHeader = res.header;
          self.handleHeadersOnRes(resHeader);

          var data = res.data;
          res.ok = res.status == http.StatusCode.Ok;

          console.debug("来自：" + url);
          console.debug(res);
          if (res.ok && data) {
            // data = json.Parse(data);
            var err = data.Error;
            if (err) {
              console.error(err);
              reject(err);
              return;
            } else {
              // file.Key = key;
              // file.OriginPath = file.Path;
              // file.Path = data.Result;
              // file.Uploaded = true;
              var result = {
                key: key,
                path: data.Result,
                file: file,
              };
              resolve(result);
              // if (!ignoreLoading) {
              //   wx.showLoading({
              //     title: title + "上传中 " + successCount + "/" + files.length,
              //     mask: true,
              //   });
              // }
            }
          } else {
            var info = `网络错误：` + json.Stringify(res);
            console.error(info);
            reject(info);
            return;
          }
        })
        .catch(function (err) {
          console.error(err);
          // reject(err);
          reject(err);
        });
    });
  };

  // self.UploadFiles = function(pattern, params) {
  //     if (!params) {
  //         return;
  //     }
  //     var finish = params.finish;
  //     var files = params.files;
  //     if (!files || !files.length) {
  //         !finish || finish();
  //         return;
  //     }

  //     var ignoreLoading = params.ignoreLoading;
  //     if (!ignoreLoading) {
  //         wx.showLoading({
  //             title: '准备上传',
  //             mask: true,
  //         });
  //     }

  //     var success = params.success;
  //     var failed = params.failed;
  //     var formData;
  //     if (params.data) {
  //         formData = {
  //             data: json.Stringify(params.data),
  //         };
  //     }
  //     self.uploadFile({
  //         url: self.getFullUrl(pattern),
  //         files: params.files,
  //         name: params.name,
  //         formData: formData,
  //         success: success,
  //         failed: function(err) {
  //             if (!ignoreLoading) {
  //                 wx.hideLoading();
  //             } !failed || failed(err);
  //         },
  //         finish: function() {
  //             if (!ignoreLoading) {
  //                 wx.hideLoading();
  //             } !finish || finish();
  //         },
  //         ignoreLoading: ignoreLoading,
  //     });
  // };

  // self.DownloadFile = function(params) {
  //     return wx.downloadFile(params);
  // };

  // self.DataReport = function(data) {
  //     var token = wxx.GetToken();
  //     self.Post('data_report', {
  //         data: {
  //             Key: {
  //                 Data: data,
  //             },
  //             Token: token,
  //         },
  //         success: function() {

  //         },
  //         failed: function(err) {
  //             wxx.ShowError(err.Info);
  //         },
  //     });
  // };

  return self;
};

net.CombineSorters = function (sorters, newSorter) {
  if (!sorters) {
    sorters = [];
  }
  var found = -1;
  for (var i = 0; i < sorters.length; i++) {
    var sorter = sorters[i];
    if (newSorter.Prop == sorter.Prop) {
      // sorter.Order = newSorter.Order;
      // sorter.Priority = newSorter.Priority;
      // return sorters;
      found = i;
      break;
    }
  }

  // newSorter放在前面，视为优先级最高
  if (found < 0) {
    sorters.Insert(0, newSorter);
  } else {
    found = sorters.RemoveAtIndex(found);
    found.Order = newSorter.Order;
    found.Priority = newSorter.Priority;
    sorters.Insert(0, found);
  }

  return sorters;
};

// module.exports = net;
export default net;
