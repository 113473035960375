<template>
  <v-card>
    <v-card-text>
      <v-container fluid>
        <v-row v-for="(item, index) in form.Items" :key="index">
          <v-col v-if="item.FormType == FormType.AGroupTitle" cols="12">
            <v-alert
              dense
              border="left"
              colored-border
              color="blue darken-1"
              elevation="2"
            >
              {{ item.Title }}
            </v-alert>
          </v-col>
          <v-col v-else-if="item.Title" cols="2">
            <v-alert
              dense
              colored-border
              color="blue darken-1"
              elevation="2"
              class="jw-txr"
              >{{ item.Title }}</v-alert
            >
          </v-col>
          <v-col v-if="item.FormType == FormType.Panel">
            <PanelMain
              :ref="'panel' + index"
              :panel="item"
              :ext="{ index }"
              @form="onForm"
            ></PanelMain>
          </v-col>
          <v-col v-else-if="item.FormType == FormType.Card">
            <PanelCard
              :ref="'panel' + index"
              :card="item"
              :ext="{ index }"
              @form="onForm"
            ></PanelCard>
          </v-col>
          <v-col v-else>
            <JWAFormItem
              :ref="'item' + index"
              :item="item"
              :index="index"
              @form="onForm"
            ></JWAFormItem>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="flex-column align-end">
      <v-btn
        v-if="submit"
        color="blue darken-1"
        class="white--text mr-8"
        large
        @click="onSubmit"
        >{{ _submitName }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import "../jwapp/jw.css";
import jw from "../jwapp/jw.js";
import JWAFormItem from "./FormItem.vue";
import PanelCard from "./PanelCard.vue";
import PanelMain from "./PanelMain.vue";

export default {
  name: "JWAForm",

  components: {
    PanelMain,
    PanelCard,
    JWAFormItem,
  },

  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    submit: {
      type: Object,
      default() {
        return {
          Name: "",
          Api: "",
          Key: null,
        };
      },
    },
  },

  computed: {
    _submitName() {
      var submit = this.submit;
      var name = submit.Name
        ? submit.Name
        : this.$vuetify.lang.t("$vuetify.Submit");
      return name;
    },
  },

  data() {
    return {
      FormType: jw.ui.FormType,
      value: {},
    };
  },

  mounted() {
    this.onFormChange(this.form);
  },

  watch: {
    form(form) {
      this.onFormChange(form);
    },
  },

  methods: {
    onFormChange(form) {
      if (form) {
        var that = this;
        jw.utils.ArrayForeach(form.Items, function (item, index) {
          switch (item.FormType) {
            case jw.ui.FormType.Panel: {
              // Item为一个Panel插入到表单中
              that.$nextTick(function () {
                var ref = that.refPanel(index);
                if (ref.doRoute) {
                  ref.doRoute();
                }
              });
              break;
            }
          }
        });
      }
    },

    onSubmit() {
      // var value = this.value;
      var that = this;
      var value = {};

      var fileUploads = [];
      jw.utils.ArrayForeach(this.form.Items, function (item, formIndex) {
        var key = item.Name;
        var itemRef = that.$refs["item" + formIndex];
        if (itemRef) {
          if (itemRef[0]) {
            itemRef = itemRef[0];
          }
        }
        if (itemRef && itemRef.getValue) {
          var val = itemRef.getValue(key, formIndex, fileUploads);
          value[key] = val;
        }
      });

      if (!jw.utils.ArrayIsEmpty(fileUploads)) {
        that.$store.ShowLoading("文件上传中");
        Promise.all(fileUploads).then(function (files) {
          jw.utils.ArrayForeach(files, function (result) {
            if (!result) {
              return;
            }
            var { key, path, file } = result;
            var item = that.form.Items[file.formIndex];
            item.Files[file.fileIndex].Uploaded = true;
            item.Files[file.fileIndex].Path = path;
            item.Value = item.Files;
            value[key] = item.Value;
          });
          that.$store.HideLoading();
          that.doSubmit(value);
        });
      } else {
        that.doSubmit(value);
      }
    },

    doSubmit(value) {
      var that = this;
      var client = this.$store.state.client;
      var api = this.submit.Api;
      var key = this.submit.Key;
      if (!api) {
        that.$emit("submit", {});
        return;
      }
      value = jw.utils.Copy(key, value, true);
      console.debug("submit:", value);
      client
        .Post(api, {
          data: {
            Key: value,
          },
        })
        .then(function () {
          that.$emit("submit", {
            submitName: that._submitName,
            submitApi: api,
            value: value,
          });
        })
        .catch(function (err) {
          that.$store.ShowError(err.Info);
        });
    },

    onCancel() {
      this.$emit("cancel", {});
    },

    onFileChange(data) {
      // console.log(value);
      var item = this.form.Items[data.formIndex];
      item.Files = data.value;
      this.form.Items[data.formIndex] = item;
    },

    onFilesChange(data) {
      var item = this.form.Items[data.formIndex];
      item.Files = data.value;
      this.form.Items[data.formIndex] = item;
    },

    onForm(res) {
      this.$emit("form", res);
    },
    onFormSubmit() {
      this.onFormChange(this.form);
    },

    refPanel(index) {
      var ref = this.$refs["panel" + index];
      if (ref) {
        if (ref[0]) {
          ref = ref[0];
        }
      }
      return ref;
    },
  },
};
</script>
