import md5 from "js-md5";
import jw from "../jwapp/jw.js";

var ui = {};

ui.form = {};
ui.form.HandleInputValue = function(input) {
  var val = input.Value;
  switch (input.OutputType) {
    case "int": {
      val = jw.utils.ParseInt(val, 0);
      break;
    }
    case "float": {
      val = jw.utils.ParseFloat(val, 0);
      break;
    }
    case "password": {
      val = md5(val);
      break;
    }
  }
  return val;
};

export default ui;
