// import VueI18n from "vue-i18n";
import zhHans from "./zh-Hans";
import en from "./en";

export default {
    locale: "zhHans",
    messages: {
        zhHans: zhHans,
        en: en,
    },
}

// console.log("zh:", zhHans)

// export default {
//     locales: { zhHans, en },
//     current: "zhHans",
// }