<template>
  <div class="Panel jw-vert jw-s100">
    <PanelMain
      class="jw-s100"
      ref="panel"
      :panel="Panel"
      table-height="500px"
      @form="onForm(0, arguments[0])"
    ></PanelMain>

    <v-dialog
      v-for="(formInfo, index) in formInfos"
      :key="index"
      v-model="formInfo.showForm"
      scrollable
      max-width="90%"
      transition="dialog-transition"
    >
      <JWAForm
        :ref="'form' + index"
        :form="formInfo.form"
        :submit="formInfo.submit"
        @submit="onSubmit(index, arguments[0])"
        @cancel="formInfo.showForm = false"
        @form="onForm(index + 1, arguments[0])"
      ></JWAForm>
    </v-dialog>
  </div>
</template>

<script>
import "../jwapp/jw.css";
import JWAForm from "./Form.vue";
// import jw from "../jwapp/jw"
import jwa from "./jw.aman";
import PanelMain from "./PanelMain.vue";

export default {
  name: "APanel",

  components: {
    JWAForm,
    PanelMain,
  },

  data() {
    return {
      ValueType: jwa.ValueType,
      PanelType: jwa.PanelType,

      Panel: {
        New: null,
        Type: 0,
      },

      formInfos: [],
    };
  },

  mounted() {
    this.formInfos = [];
    for (var i = 0; i < 5; i++) {
      this.formInfos.push({
        showForm: false,
        form: {},
        submit: {
          Name: "",
          Api: "",
          Key: "",
        },
      });
    }

    this.doRoute(this.$route);
  },

  watch: {
    $route(route) {
      this.doRoute(route);
    },
  },

  methods: {
    doRoute(route) {
      var query = route.query;
      if (query) {
        var api = query.api;
        if (api) {
          var client = this.$store.state.client;
          var that = this;
          var lang = query.lang;
          delete query.api;
          var data = {
            lang: lang,
            data: {
              Key: query, // NOTE 将页面参数原封不动作为请求参数发送到服务器
            },
          };
          client
            .Post(api, data)
            .then(function (res) {
              if (!res) {
                return;
              }
              that.Panel = res;

              var panel = that.$refs.panel;
              if (panel && panel.doRoute) {
                that.$nextTick(function () {
                  panel.doRoute(route);
                });
              }
            })
            .catch(function (err) {
              that.$store.ShowError(err.Info);
            });
        }
      }
    },

    onForm(index, res) {
      if (index >= this.formInfos.length) {
        return;
      }
      var formInfo = this.formInfos[index];
      formInfo.form = res.form;
      formInfo.submit = res.submit;
      formInfo.showForm = res.showForm;
      // console.error(formInfo);
    },

    onSubmit(index, res) {
      if (index >= this.formInfos.length) {
        return;
      }
      var formInfo = this.formInfos[index];

      var name = res.submitName;
      // jwa.utils.ShowSuccess(name + "成功", this);
      if (name) {
        this.$store.ShowInfo(name + "成功");
      }

      formInfo.showForm = false;
      var that = this;
      if (index > 0) {
        index--;
        var form = that.$refs["form" + index];
        if (form[0]) {
          form = form[0];
        }
        form.onFormSubmit();
      } else {
        var panel = that.$refs.panel;
        panel.onSubmit();
      }
    },
  },
};
</script>
