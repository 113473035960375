import Vue from "vue";
import Vuetify from "vuetify/lib";
import VueI18n from "vue-i18n";
import lang from "../lang/lang";

Vue.use(Vuetify);
Vue.use(VueI18n);

const i18n = new VueI18n(lang);
export default new Vuetify({
  lang: {
    // NOTE vuetify内部翻译不能扩展，尚待研究。这里直接指定current是有效果的，暂且使用。如指定zhHans，vuetify的内部控件就会变成中文
    current: i18n.locale,
    t: (key, ...params) => i18n.t(key, params),
  },
});
