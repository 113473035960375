var utils = {};

utils.ShowError = function(err, page) {
  page.err = {
    show: true,
    info: err.Info,
  };
  if (utils.showErrorId) {
    clearTimeout(utils.showErrorId);
  }
  utils.showErrorId = setTimeout(function() {
    page.err = {
      show: false,
      info: "",
    };
  }, 3000);
};

utils.ShowSuccess = function(info, page) {
  page.success = {
    show: true,
    info: info,
  };
  if (utils.showSuccessId) {
    clearTimeout(utils.showSuccessId);
  }
  utils.showSuccessId = setTimeout(function() {
    page.success = {
      show: false,
      info: "",
    };
  }, 3000);
};

utils.RouterPath = function(router, path) {
  var data = {
    path: path,
  };
  var lang = router.currentRoute.query.lang;
  if (lang) {
    data.query = {
      lang: lang,
    };
  }
  return data;
};

utils.Router = function(router, store) {
  var self = {};
  self.router = router;
  self.store = store;

  self.Push = function(path) {
    var data = {
      path: path,
    };
    var lang = self.store.getters.lang;
    if (lang) {
      data.lang = lang;
    }
    return self.router.push(data);
  };

  self.Replace = function(path) {
    var data = {
      path: path,
    };
    var lang = self.store.getters.lang;
    if (lang) {
      data.lang = lang;
    }
    return self.router.replace(data);
  };

  return self;
};

export default utils;
