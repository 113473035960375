<template>
  <v-container id="PanelCard">
    <v-row>
      <v-col>
        <v-card>
          <v-card-text v-for="(item, itemIndex) in card.Items" :key="itemIndex">
            <v-container>
              <v-row>
                <v-col cols="2">{{ item.Name }}</v-col>
                <v-col>
                  <v-textarea
                    v-if="item.Type == ValueType.Text"
                    v-model="item.Value"
                    no-resize
                    auto-grow
                    disabled
                  ></v-textarea>
                  <div
                    v-else-if="item.Type == ValueType.Color"
                    :style="{
                      background: item.Value,
                      width: '100px',
                      height: '100px',
                    }"
                  ></div>
                  <v-img
                    :class="{ 'img-bg': !!item.Value }"
                    v-else-if="item.Type == ValueType.Image"
                    :src="item.Value"
                    :width="item.Width ? item.Width : '100px'"
                    :height="item.Height ? item.Height : '100px'"
                    contain
                  ></v-img>
                  <audio
                    v-else-if="item.Type == ValueType.Audio"
                    :src="item.Value"
                    controls
                  ></audio>
                  <vue-qr
                    v-else-if="item.Type == ValueType.QRCode"
                    :text="item.Value"
                  ></vue-qr>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-for="button in card.Buttons"
              :key="button.Name"
              @click="onButton(button)"
              >{{ button.Name }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueQr from "vue-qr/src/packages/vue-qr.vue";
import jwa from "./jw.aman";

export default {
  name: "APanelCard",

  components: {
    VueQr,
  },

  props: {
    card: {
      type: Object,
      default() {
        return {
          Items: [],
        };
      },
    },
  },

  data() {
    return {
      ValueType: jwa.ValueType,
    };
  },

  methods: {
    onButton(button) {
      console.log("click", button.Name);
      this.doButton(button);
    },

    doButton(button) {
      if (!button) {
        return;
      }
      var action = button.Action;
      var client = this.$store.state.client;
      var that = this;
      var api = button.Api;
      var key = button.Key;
      switch (action) {
        case jwa.PanelButtonAction.Api: {
          if (api) {
            client
              .Post(api, {
                data: {
                  Key: key,
                },
              })
              .then(function () {
                that.changeContentPage(that.page);
              })
              .catch(function (err) {
                that.$store.ShowError(err.Info);
              });
          }
          break;
        }
        case jwa.PanelButtonAction.Form: {
          if (api) {
            client
              .Post(api, {
                data: {
                  Key: key,
                },
              })
              .then(function (res) {
                if (!res) {
                  return;
                }
                that.form = res;
                that.submit = button.Submit;
                that.showForm = true;

                that.$emit("form", {
                  form: res,
                  submit: button.Submit,
                  showForm: true,
                });
              })
              .catch(function (err) {
                that.$store.ShowError(err.Info);
              });
          }
          break;
        }
        default: {
          // TODO
          break;
        }
      }
    },
  },
};
</script>

<style scoped>
.img-bg {
  background: url("../assets/bg_trans.jpg");
}
</style>
