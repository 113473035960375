<template>
  <v-icon @click="onClick()">{{icon}}</v-icon>
</template>

<script>
import "../jwapp/jw.css";
import jw from "../jwapp/jw.js";
// import jwa from "./jw.aman";

export default {
  name: "Sorter",

  components: {},

  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      order: jw.data.Order.None
    };
  },

  watch: {
    data(to) {
      this.order = to.Order;
    }
  },

  computed: {
    icon() {
      switch (this.order) {
        case jw.data.Order.Ascend: {
          return "mdi-arrow-up";
        }
        case jw.data.Order.Descend: {
          return "mdi-arrow-down";
        }
      }
      return "mdi-arrow-up-down";
    }
  },

  methods: {
    onClick() {
      switch (this.order) {
        case jw.data.Order.Ascend: {
          this.order = jw.data.Order.Descend;
          break;
        }
        case jw.data.Order.Descend: {
          this.order = jw.data.Order.Ascend;
          break;
        }
        default: {
          this.order = jw.data.Order.Descend;
          break;
        }
      }
      this.$emit("sort", {
        Prop: this.data.Prop,
        Order: this.order,
        Priority: this.data.Priority
      });
    }
  }
};
</script>

<style lang="stylus" scoped></style>
