<template>
  <v-dialog v-model="dialog" :persistent="value.Modal" max-width="290">
    <v-card>
      <v-card-title class="headline">{{value.title}}</v-card-title>

      <v-card-text>{{value.content}}</v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-show="value.showCancel" color="normal" text @click="onCancel">{{value.cancelText}}</v-btn>
        <v-btn color="primary" text @click="onConfirm">{{value.confirmText}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {},

  data() {
    return {
      dialog: false,
      value: {
        modal: false,
        title: "",
        content: "",
        showCancel: true,
        confirmText: "",
        cancelText: "",
        success: function() {}
      }
    };
  },

  methods: {
    Show(value) {
      if (!value) {
        return;
      }
      if (value.showCancel === undefined) {
        value.showCancel = true;
      }
      if (!value.confirmText) {
        value.confirmText = "确定";
      }
      if (!value.cancelText) {
        value.cancelText = "取消";
      }
      this.value = value;
      this.dialog = true;
    },

    onConfirm() {
      var on = this.value.success;
      if (on) {
        var res = {
          confirm: true
        };
        on(res);
      }
      this.dialog = false;
    },

    onCancel() {
      var on = this.value.success;
      if (on) {
        var res = {
          cancel: true
        };
        on(res);
      }
      this.dialog = false;
    }
  }
};
</script>

