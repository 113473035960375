<template>
  <transition name="fade">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </transition>
</template>

<script>
export default {
  name: "App",

  data() {
    return {};
  }
};
</script>
