var strings = {};

strings.Join = function(sep) {
  if (!sep) {
    sep = "";
  }
  var result = "";
  for (var i = 1; i <= arguments.length; i++) {
    var arg = arguments[i];
    if (arg) {
      if (result) {
        result += sep + arg;
      } else {
        result = arg;
      }
    }
  }
  return result;
};

strings.IsEmpty = function(str) {
  return !str || str === "";
};

strings.IsBlank = function(str) {
  return !str || /^\s*$/.test(str);
};

// module.exports = strings;
export default strings;
