var json = {};
/**
 * 系统的json解析函数，建议使用下面的parse_deep
 */
// self.parse = JSON.parse;
json.Parse = function(text) {
	try {
		return JSON.parse(text);
	} catch (err) {
		console.error(err);
		return null;
	}
};

/**
 * 系统的json序列化函数
 */
json.Stringify = JSON.stringify;

/**
 * 递归解析json对象，如果json字符串中有数组会递归解析里面的对象
 * @param json json字符串
 */
json.ParseDeep = function(json) {
	var obj;
	try {
		obj = JSON.parse(json);
	} catch (e) {
		console.error(e);
	}

	for (var key in obj) {
		var value = obj[key];
		if (Array.isArray(value)) {
			for (var i = 0; i < value.length; i++) {
				var v = value[i];
				if (typeof v == 'string') {
					try {
						value[i] = self.parseDeep(v);
					} catch (e) {
						console.error(e);
					}
				}
			}
			obj[key] = value;
		}
	}

	return obj;
};

// module.exports = json;
export default json;