<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <!-- TODO 考虑改成v-data-table -->
        <v-simple-table fixed-header dense :height="tableHeight">
          <thead>
            <tr>
              <!-- <th v-for="(column, index) in list.Columns" :key="index">
                  {{ column }}
                </th>-->
              <th v-for="(column, index) in list.Columns" :key="index">
                <span class="jw-title">{{ column.Name }}</span>
                <JWASorter
                  v-if="column.Sorter"
                  :data="column.Sorter"
                  @sort="onSort"
                ></JWASorter>

                <v-menu v-if="column.Filter" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn class v-on="on">{{
                      column.Filter.Items[0].Name
                    }}</v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(filterItem, filterIndex) in column.Filter.Items"
                      :key="filterIndex"
                      :color="filterItem.Color"
                    >
                      <v-list-item-title>{{
                        filterItem.Name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </th>
              <th v-if="hasButtons">
                <span class="jw-title">{{
                  $vuetify.lang.t("$vuetify.Operation")
                }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in listRows" :key="rowIndex">
              <td v-for="(item, itemIndex) in row.Items" :key="itemIndex">
                <div class="jw-hort">
                  <span
                    class="text-wrap jw-title3"
                    v-if="item.Type == ValueType.Text"
                    >{{ item.Value }}</span
                  >
                  <v-chip
                    v-else-if="item.Type == ValueType.Color"
                    class="jw-s100"
                    :color="item.Value"
                    label
                  ></v-chip>
                  <v-img
                    :class="{ 'img-bg': !!item.Value }"
                    :style="{
                      width: item.Width ? item.Width : '48px',
                      height: item.Height ? item.Height : '32px',
                    }"
                    v-else-if="item.Type == ValueType.Image"
                    :src="item.Value"
                    contain
                  ></v-img>
                  <v-btn
                    v-if="item.Button"
                    class="pa-0 ma-2"
                    @click="onButton(item.Button)"
                    text
                    x-small
                    outlined
                    color="grey lighten-1"
                    >{{ item.Button.Name }}</v-btn
                  >
                </div>
              </td>
              <td>
                <div
                  class="jw-hort"
                  v-for="(btnRow, i) in row.BtnRows"
                  :key="i"
                >
                  <v-btn
                    class="pa-0"
                    :class="j > 0 ? 'ma-1' : ''"
                    v-for="(button, j) in btnRow"
                    :key="j"
                    @click="onButton(button)"
                    text
                    x-small
                    color="blue darken-3"
                    >{{ button.Name }}</v-btn
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination"
          :length="pagination.Length"
          :total-visible="pagination.Visible"
          v-model="page"
          @input="onPageInput"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>

  <!-- <v-container class="pagination">
      <v-pagination
        v-if="pagination"
        :length="pagination.Length"
        :total-visible="pagination.Visible"
        v-model="page"
        @input="onPageInput"
      ></v-pagination>
    </v-container> -->

  <!-- <v-dialog
      v-model="showForm"
      persistent
      scrollable
      max-width="800px"
      transition="dialog-transition"
    >
      <JWAForm
        :form="form"
        :submit="submit"
        @submit="onSubmit"
        @cancel="showForm = false"
      ></JWAForm>
    </v-dialog>-->

  <!-- <v-alert :value="err.show" type="error">{{ err.info }}</v-alert>
    <v-alert :value="success.show" type="success">{{ success.info }}</v-alert> -->
</template>

<script>
import "../jwapp/jw.css";
import jw from "../jwapp/jw.js";
import jwa from "./jw.aman";
// import JWAForm from "./Form.vue";
import JWASorter from "./Sorter.vue";

export default {
  name: "APanelTable",

  components: {
    // JWAForm,
    JWASorter,
  },

  props: {
    panel: {
      type: Object,
    },
    keywords: {
      type: Object,
    },
    ext: {
      type: Object,
    },
    tableHeight: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      ValueType: jwa.ValueType,
      //   PanelType: jwa.PanelType,

      //   Panel: {
      //     New: null,
      //     Type: 0,
      //   },
      // Panel: null,

      sorters: null,
      filters: null,

      showForm: false,
      form: {},
      submit: {
        Name: "",
        Api: "",
        Key: "",
      },

      list: {
        Columns: [],
        Rows: [],
      },
      pagination: null,
      page: 1,

      err: {
        show: false,
        info: "",
      },
      success: {
        show: false,
        info: "",
      },
    };
  },

  computed: {
    hasButtons() {
      var list = this.list;
      if (!jw.utils.ArrayIsEmpty(list.Rows)) {
        var row = list.Rows[0];
        return !jw.utils.ArrayIsEmpty(row.Buttons);
      }
      return false;
    },

    listRows() {
      var list = this.list;
      var listRows = [];
      jw.utils.ArrayForeach(list.Rows, (row) => {
        var btns = [];
        var btnRow;
        const itemPerBtnRow = 6;
        jw.utils.ArrayForeach(row.Buttons, (btn, index) => {
          if (index % itemPerBtnRow == 0) {
            if (btnRow) {
              btns.push(btnRow);
            }
            btnRow = [];
          }
          btnRow.push(btn);
        });
        if (btnRow) {
          btns.push(btnRow);
        }
        row.BtnRows = btns;
        listRows.push(row);
      });
      return listRows;
    },
  },

  watch: {
    keywords() {
      this.doContent();
    },
  },

  methods: {
    doContent(limit) {
      var client = this.$store.state.client;
      var panel = this.panel;
      var that = this;
      var api = panel.ContentApi;
      var key = panel.ContentKey;
      if (!key) {
        key = {};
      }
      var keywords = this.keywords;
      key = jw.utils.Copy(keywords, key);
      client
        .Post(api, {
          data: {
            Key: key,
            Limit: limit,
            Sorters: this.sorters,
            Filters: this.filters,
          },
        })
        .then(function (res) {
          if (!res) {
            return;
          }
          that.list = res.Content ? res.Content : [];
          that.pagination = res.Pagination;
        })
        .catch(function (err) {
          that.$store.ShowError(err.Info);
        });
    },

    doButton(button) {
      if (!button) {
        return;
      }
      var action = button.Action;
      var client = this.$store.state.client;
      var that = this;
      var api = button.Api;
      var key = button.Key;
      switch (action) {
        case jwa.PanelButtonAction.Api: {
          if (api) {
            client
              .Post(api, {
                data: {
                  Key: key,
                },
              })
              .then(function () {
                that.changeContentPage(that.page);
              })
              .catch(function (err) {
                that.$store.ShowError(err.Info);
              });
          }
          break;
        }
        case jwa.PanelButtonAction.Form: {
          if (api) {
            // 发送请求获取表单数据，然后让父级组件弹出表单
            client
              .Post(api, {
                data: {
                  Key: key,
                },
              })
              .then(function (res) {
                if (!res) {
                  return;
                }
                that.form = res;
                that.submit = button.Submit;
                that.showForm = true;

                that.$emit("form", {
                  form: res,
                  submit: button.Submit,
                  showForm: true,
                  ext: that.ext,
                });
              })
              .catch(function (err) {
                that.$store.ShowError(err.Info);
              });
          }
          break;
        }
        case jwa.PanelButtonAction.CopyValue: {
          if (!button.Value) {
            break;
          }
          var text = button.Value.toString();
          this.$copyText(text).then(function () {
            // jwa.utils.ShowSuccess("复制【" + button.Value + "】成功", that);
            that.$store.ToastSuccess("复制【" + button.Value + "】成功");
          });
          break;
        }
        default: {
          // TODO
          break;
        }
      }
    },

    onButton(button) {
      //   console.log("click", button.Name);
      this.doButton(button);
    },

    changeContentPage(page) {
      var p = this.pagination;
      var limit;
      if (p) {
        limit = {
          Start: (page - 1) * p.NumPerPage,
          Count: p.NumPerPage,
        };
      }
      this.doContent(limit);
    },

    onPageInput(value) {
      this.changeContentPage(value);
    },

    onSubmit(e) {
      var name = e.submitName;
      // jwa.utils.ShowSuccess(name + "成功", this);
      this.$store.ShowInfo(name + "成功");
      this.showForm = false;
      this.$emit("form", {
        showForm: false,
        ext: this.ext,
      });
      this.changeContentPage(this.page);
    },

    onSort(sorter) {
      this.sorters = jw.net.CombineSorters(this.sorters, sorter);
      this.doContent();
    },
  },
};
</script>

<style scoped>
.img-bg {
  background: url("../assets/bg_trans.jpg");
}
</style>
