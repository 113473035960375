<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="grey darken-2" dark flat>
                <v-toolbar-title>
                  {{
                  $vuetify.lang.t("$vuetify.AppTitle")
                  }}
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    id="Account"
                    name="Account"
                    :label="$vuetify.lang.t('$vuetify.Account.Label')"
                    v-model="User.Account"
                    :rules="ruleAccount"
                    type="text"
                    clearable
                    required
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    name="Password"
                    :label="$vuetify.lang.t('$vuetify.Password.LabelOrigin')"
                    v-model="User.Password"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="rulePassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    clearable
                    counter
                    required
                  ></v-text-field>

                  <v-text-field
                    id="password_new"
                    name="PasswordNew"
                    :label="$vuetify.lang.t('$vuetify.Password.LabelNew')"
                    v-model="User.PasswordNew"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="rulePasswordNew()"
                    clearable
                    counter
                    required
                  ></v-text-field>

                  <v-text-field
                    id="password_repeat"
                    name="PasswordRepeat"
                    :label="$vuetify.lang.t('$vuetify.Password.LabelRepeat')"
                    v-model="User.PasswordRepeat"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="rulePasswordRepeat()"
                    clearable
                    counter
                    required
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  :disabled="!valid"
                  color="blue darken-1"
                  class="white--text mr-4"
                  @click="onSubmit"
                >{{ $vuetify.lang.t("$vuetify.PasswordModity") }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-alert :value="err.show" type="error">{{ err.info }}</v-alert>
  </v-app>
</template>

<script>
import md5 from "js-md5";
import jw from "../jwapp/jw.js";
import jwa from "./jw.aman.js";

export default {
  name: "JWAPasswordModity",

  data() {
    return {
      valid: false,
      ruleAccount: [v => !!v || "请填写账号"],
      rulePassword: [v => !!v || "请填写原密码"],
      showPassword: false,
      User: {
        Account: "",
        Password: "",
        PasswordNew: "",
        PasswordRepeat: ""
      },

      err: {
        show: false,
        info: ""
      }
    };
  },

  methods: {
    rulePasswordNew() {
      return [
        v => {
          return !!v || "请填写新密码";
        }
      ];
    },

    rulePasswordRepeat() {
      return [
        v => {
          return !!v || "请重复新密码";
        },
        v => {
          if (v != this.User.PasswordNew) {
            return "两次填写的新密码不一致";
          }
          return true;
        }
      ];
    },

    onSubmit() {
      var client = this.$store.state.client;
      var lang = this.$route.query.lang;

      // var key = {
      //   Account: this.User.Account,
      //   Password: this.User.Password,
      //   PasswordNew: this.User.PasswordNew,
      //   PasswordRepeat: this.User.PasswordRepeat
      // };
      var key = jw.utils.New(this.User);
      if (key.Password) {
        key.Password = md5(key.Password);
      }
      if (key.PasswordNew) {
        key.PasswordNew = md5(key.PasswordNew);
      }
      if (key.PasswordRepeat) {
        key.PasswordRepeat = md5(key.PasswordRepeat);
      }
      var that = this;
      client
        .Post("passmod", {
          lang: lang,
          data: {
            Key: key
          }
        })
        .then(function() {
          that.$refs.form.reset();
          var pages = that.$store.state.pages;
          var router = that.$router;
          router.replace(jwa.utils.RouterPath(router, pages.login));
        })
        .catch(function(err) {
          console.error(err);
          jwa.utils.ShowError(err, that);
        });
    }
  }
};
</script>
