<template>
  <!-- <v-container>
    <v-row>
      <v-col>
        <v-file-input :accept="mimeTypes[type]" show-size :disabled="disabled" @change="onChange"></v-file-input>
      </v-col>
      <v-col>
        <v-img v-if="type=='image'" :src="url" width="350px" height="210px" contain></v-img>
        <audio v-if="type=='audio'" :src="url" controls></audio>
        <video v-if="type=='video'" :src="url" controls></video>
      </v-col>
    </v-row>
  </v-container>-->
  <div>
    <v-file-input
      :accept="mimeTypes"
      show-size
      :disabled="disabled"
      @change="onChange"
      v-model="file"
      dense
    ></v-file-input>
    <v-container>
      <v-row>
        <span
          v-if="
            (maxWidth || maxHeight) &&
            (curWidth != maxWidth || curHeight != maxHeight)
          "
          class="red--text"
          >建议图片大小 {{ maxWidth }} × {{ maxHeight }}</span
        >
        <span v-else-if="maxWidth || maxHeight"
          >建议图片大小 {{ maxWidth }} × {{ maxHeight }}</span
        >
      </v-row>
      <v-row>
        <span v-if="curWidth || curHeight"
          >当前图片大小 {{ curWidth }} × {{ curHeight }}</span
        >
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <v-img
            ref="img"
            :class="{ 'img-bg': !!url }"
            v-if="fileType == AFileInputType.Image"
            :src="url"
            width="350px"
            height="210px"
            contain
            @load="onImageLoad"
          ></v-img>
          <audio
            v-if="fileType == AFileInputType.Audio"
            :src="url"
            controls
          ></audio>
          <video
            v-if="fileType == AFileInputType.Video"
            :src="url"
            width="350px"
            height="210px"
            controls
          ></video>
        </v-col>
        <v-col v-if="preset">
          <span>预设</span>
          <v-img
            ref="img"
            :class="{ 'img-bg': !!preset }"
            v-if="fileType == AFileInputType.Image"
            :src="preset"
            width="350px"
            height="210px"
            contain
            @load="onImageLoad"
          ></v-img>
          <audio
            v-if="fileType == AFileInputType.Audio"
            :src="preset"
            controls
          ></audio>
          <video
            v-if="fileType == AFileInputType.Video"
            :src="preset"
            width="350px"
            height="210px"
            controls
          ></video>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import jwa from "../jwaman/jw.aman.js";
import jw from "../jwapp/jw.js";

export default {
  name: "JWAFileInput",

  components: {},

  props: {
    // type: {
    //   type: String,
    //   default: "file"
    // },
    type: {
      type: Number,
      default: jwa.AFileInputType.File,
    },
    // ImageOps
    value: {
      type: Array,
      default() {
        // var obj = {
        //   Path: "",
        //   OriginPath: "",
        //   Op: "",
        //   Uploaded: false
        // };
        return [];
      },
    },
    preset: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
    maxHeight: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formIndex: {
      type: Number,
      default: -1,
    },
  },

  data() {
    return {
      AFileInputType: jwa.AFileInputType,
      // mimeTypes: {
      //   file: "*",
      //   image: "image/png, image/jpeg, image/svg+xml",
      //   audio: "audio/mp3",
      //   video: "video/mp4"
      // },
      // icons: {
      //   file: "",
      //   image: "mdi-camera",
      //   audio: "mdi-volumn"
      // },
      mt: null,
      url: "",
      file: null,
      curWidth: 0,
      curHeight: 0,
      // mimeType: null
    };
  },

  computed: {
    mimeTypes() {
      var mt = this.mt;

      if (jw.utils.FlagsCheck(this.type, jwa.AFileInputType.File)) {
        return mt[jwa.AFileInputType.File];
      }

      // console.error("type:", this.type);
      var t = [];
      if (jw.utils.FlagsCheck(this.type, jwa.AFileInputType.Image)) {
        t.AddAll(mt[jwa.AFileInputType.Image]);
      }
      if (jw.utils.FlagsCheck(this.type, jwa.AFileInputType.Audio)) {
        t.AddAll(mt[jwa.AFileInputType.Audio]);
      }
      if (jw.utils.FlagsCheck(this.type, jwa.AFileInputType.Video)) {
        t.AddAll(mt[jwa.AFileInputType.Video]);
      }
      // console.error(t);
      return t.join(",");
    },

    fileType() {
      // var mt = this.mt;
      // var ok = false;
      // var ft = jwa.AFileInputType.File;
      // var that = this;
      // jw.utils.ArrayEach(mt[jwa.AFileInputType.Image], function(t) {
      //   if (t == that.mimeType) {
      //     ft = jwa.AFileInputType.Image;
      //     ok = true;
      //     return false;
      //   }
      //   return true;
      // });
      // if (ok) {
      //   return ft;
      // }

      // jw.utils.ArrayEach(mt[jwa.AFileInputType.Audio], function(t) {
      //   if (t == that.mimeType) {
      //     ft = jwa.AFileInputType.Audio;
      //     ok = true;
      //     return false;
      //   }
      //   return true;
      // });
      // if (ok) {
      //   return ft;
      // }

      // jw.utils.ArrayEach(mt[jwa.AFileInputType.Video], function(t) {
      //   if (t == that.mimeType) {
      //     ft = jwa.AFileInputType.Video;
      //     ok = true;
      //     return false;
      //   }
      //   return true;
      // });
      // if (ok) {
      //   return ft;
      // }
      //
      // return ft;

      var value = this.value;
      if (jw.utils.ArrayIsEmpty(value)) {
        return this.type;
      }
      var last = value[value.length - 1];
      var mimeType = last.MimeType;
      if (!mimeType) {
        return jwa.AFileInputType.File;
      }
      if (mimeType.startsWith("image/")) {
        return jwa.AFileInputType.Image;
      }
      if (mimeType.startsWith("audio/")) {
        return jwa.AFileInputType.Audio;
      }
      if (mimeType.startsWith("video/")) {
        return jwa.AFileInputType.Video;
      }
      return jwa.AFileInputType.File;
    },
  },

  beforeMount() {
    if (!this.mt) {
      var mt = {};
      mt[jwa.AFileInputType.File] = "*";
      mt[jwa.AFileInputType.Image] = [
        "image/png",
        "image/jpeg",
        "image/svg+xml",
        "image/gif",
      ];
      mt[jwa.AFileInputType.Audio] = ["audio/mp3"];
      mt[jwa.AFileInputType.Video] = ["video/mp4"];
      this.mt = mt;
    }

    var value = this.value;
    this.valueToUrl(value);
  },

  watch: {
    value: function (value) {
      this.valueToUrl(value);
    },
  },

  methods: {
    onChange(file) {
      // var value = this.value;
      var value;
      var remoteFile = {};
      var fileIndex = 0;
      if (jw.utils.ArrayIsEmpty(this.value)) {
        value = [remoteFile];
      } else {
        value = [];
        value.AddAll(this.value);
        fileIndex = value.length - 1;
        var lastFile = value[fileIndex];
        lastFile.Op = jw.net.RemoteFileOp.Delete;
        lastFile.Uploaded = true;
        value.push(remoteFile);
        fileIndex++;
      }
      remoteFile.Path = "";
      remoteFile.OriginPath = "";
      if (file) {
        remoteFile.Op = jw.net.RemoteFileOp.Insert;
        remoteFile.Uploaded = false;
        remoteFile.MimeType = file.type;
        // this.mimeType = file.type;
      }
      // } else {
      //   remoteFile.Op = jw.net.RemoteFileOp.Delete;
      //   remoteFile.Uploaded = true;
      // }
      remoteFile.File = file;
      remoteFile.FileIndex = fileIndex;

      // this.preview(file); // NOTE 发送事件到外部，再引起value变化重新生成预览，不在这里调用了
      // this.valueToUrl(value);
      this.value = value; // NOTE 触发value变化
      console.log("file change:", file);

      this.$emit("change", {
        value: value,
        formIndex: this.formIndex,
      });
    },

    onImageLoad() {
      var img = this.$refs.img;
      var { width, height } = img.image;
      this.curWidth = width;
      this.curHeight = height;
    },

    valueToUrl(value) {
      var url;
      if (!jw.utils.ArrayIsEmpty(value)) {
        var file = value[value.length - 1];
        if (file.File) {
          this.preview(file.File);
          return;
        }
        // this.mimeType = file.MimeType;
        if (file.Path && file.Path != "") {
          url = file.Path;
          this.file = new File([], jw.utils.Base(file.Path));
        }
      } else {
        this.file = null;
      }
      this.setUrl(url);
    },

    preview(file) {
      if (!file) {
        // console.log("setfile:null");
        this.setUrl();
        return;
      }
      // switch (this.type) {
      //   case "image":
      //   case "audio":
      //   case "video": {
      //     // console.log("setfile:", file);
      //     this.setUrl(URL.createObjectURL(file));
      //     break;
      //   }
      // }
      if (!jw.utils.FlagsCheck(this.type, jwa.AFileInputType.File)) {
        // this.mimeType = file.type;
        this.setUrl(URL.createObjectURL(file));
      }
    },

    setUrl(url) {
      // console.log("seturl:", url);
      if (jw.strings.IsEmpty(url)) {
        // console.log("filetype:", this.fileType);
        switch (this.fileType) {
          case jwa.AFileInputType.Image: {
            url = require("./image-input.png");
            break;
          }
        }
      }
      this.url = url;
    },
  },
};
</script>

<style scoped>
.img-bg {
  background: url("../assets/bg_trans.jpg");
}
</style>
