var ui = {};

ui.ButtonOp = {
  None: 0,
  Refresh: 1,
  Delete: 2,
  MoveToNextTap: 3,
};

ui.FormType = {
  Input: 1,
  ImageInput: 2,
  TextArea: 3,
  TagInput: 4,
  Selector: 101,
  Picker: 200,
  TimePicker: 201,
  ImagePicker: 202,
  ColorPicker: 203,

  AGroupTitle: 1001,
  AFileInput: 1101,
  ADatePicker: 1102,
  ATimePicker: 1103,
  ADatetimePicker: 1104,
  AColorPicker: 1105,
  AFilesInput: 1201,
  ATextEditor: 1202,
  TableContent: 1601,
  Panel: 10001,
};

module.exports = ui;
