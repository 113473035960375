<template>
  <v-dialog v-model="loading" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        {{value.title}}
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      value: {}
    };
  },

  methods: {
    Show(value) {
      this.value = value;
      this.loading = true;
    },

    Hide() {
      this.loading = false;
    }
  }
};
</script>

