<template>
  <v-autocomplete
    ref="input"
    :value="value"
    :items="items"
    :label="title"
    multiple
    chips
    deletable-chips
    hide-no-data
    @update:search-input="onUpdateSearchInput"
  ></v-autocomplete>
</template>

<script>
import jw from "../jwapp/jw.js";

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: "",
    },
  },

  methods: {
    onUpdateSearchInput(str) {
      if (!str) {
        return;
      }
      if (str.endsWith(" ")) {
        var word = str.trim();
        this.items = jw.utils.ArrayAddUnique(this.items, word);
        this.value = jw.utils.ArrayAddUnique(this.value, word);
        this.$refs.input.blur(); // 强行失焦，清除word
      }
    },
  },
};
</script>

<style scoped></style>
