<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      color="grey darken-2"
      class="white--text"
      app
      permanent
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title white--text">
            {{ $vuetify.lang.t("$vuetify.AppTitle") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="page in Pages"
          :key="page.Path"
          :to="page.Path"
          replace
        >
          <v-list-item-content @click="onPage(page)">
            <v-list-item-title class="white--text">
              {{ page.Name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-title class="title">
        {{ User.Name }}，{{ $vuetify.lang.t("$vuetify.Welcome") }}
      </v-toolbar-title>

      <v-btn class="ml-2" @click="onQuit">退出</v-btn>
    </v-app-bar>

    <v-content class="main">
      <router-view></router-view>
      <ADialog ref="dialog"></ADialog>
      <ALoading ref="loading"></ALoading>
    </v-content>

    <v-footer app color="grey darken-2" class="white--text">
      <span>{{ $vuetify.lang.t("$vuetify.Enterprise") }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import "../jwapp/jw.css";
import jw from "../jwapp/jw.js";
import ADialog from "./Dialog";
import jwa from "./jw.aman.js";
import ALoading from "./Loading";

export default {
  name: "JWAman",

  components: {
    ADialog,
    ALoading,
  },

  data() {
    return {
      drawer: true,
      title: "",
      User: {},
      Pages: [],
      lang: "",
      hasFetchMain: false,

      err: {
        show: false,
        info: "",
      },
      success: {
        show: false,
        info: "",
      },
    };
  },

  watch: {
    $route(to) {
      var pages = this.$store.state.pages;
      if (!to.path.startsWith(pages.aman)) {
        return;
      }
      this.fetchMain();
    },
  },

  mounted() {
    this.fetchMain();

    var that = this;
    this.$store.commit("setClientDialog", this.$refs.dialog);
    this.$store.ShowDialog = function (value) {
      that.$refs.dialog.Show(value);
    };
    this.$store.ShowError = function (err) {
      that.$refs.dialog.Show({
        title: err,
        showCancel: false,
        confirmText: "知道了",
      });
    };
    this.$store.ShowInfo = this.$store.ShowError;
    this.$store.ShowLoading = function (info) {
      that.$refs.loading.Show({
        title: info,
      });
    };
    this.$store.HideLoading = function () {
      that.$refs.loading.Hide();
    };

    this.$store.commit("setClientLoading", {
      showLoading: this.$store.ShowLoading,
      hideLoading: this.$store.HideLoading,
    });
  },

  methods: {
    fetchMain() {
      if (this.hasFetchMain) {
        return;
      }
      var that = this;
      var client = this.$store.state.client;
      var lang = this.$route.query.lang;
      client
        .Post("main", {
          lang: lang,
        })
        .then(function (res) {
          that.User = res.User;
          if (res.PageTree) {
            var pages = res.PageTree.Pages;
            that.Pages = pages;
          }

          if (!jw.utils.ArrayIsEmpty(pages)) {
            var page = pages[0];
            that.$router.replace({
              path: page.Path,
            });
            that.title = page.Name;

            that.hasFetchMain = true;
          }
        })
        .catch(function (err) {
          console.error(err);
        });
    },

    onPage(page) {
      this.title = page.Name;
    },

    onQuit() {
      var that = this;
      this.$store.ShowDialog({
        title: "确认退出",
        content: "是否退出当前账号？",
        success: function (res) {
          if (res.confirm) {
            jw.net.SetToken("");
            that.title = "";
            that.User = {};
            that.Pages = [];
            var pages = that.$store.state.pages;
            that.$router
              .replace(jwa.utils.RouterPath(that.$router, pages.login))
              .then(() => {
                that.hasFetchMain = false;
              });
          }
        },
      });
    },
  },
};
</script>
