var config = {};

config.Get = function (mode) {
    var cfg = {};
    switch (mode) {
        case "default":
        case "local": {
            cfg.Url = "http://localhost:23008";
            break;
        }
        case "remote": {
            cfg.Url = "https://szhbjh-as.saibo0.com";
            break;
        }
    }
    return cfg;
};

export default config;