<template>
  <v-container>
    <v-row>
      <v-icon class="ml-2" @click="onAdd">mdi-plus-circle-outline</v-icon>
    </v-row>
    <v-row v-for="(input, index) in inputs" :key="index">
      <v-col>
        <JWFileInput
          :ref="'input' + index"
          :type="type"
          :value="input"
          :disabled="disabled"
          @change="onChange"
        ></JWFileInput>
      </v-col>
      <!-- <v-col>
        <v-icon class="ml-2" @click="onDel">mdi-minus-circle-outline</v-icon>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import jw from "../jwapp/jw.js";
import jwa from "../jwaman/jw.aman.js";

import JWFileInput from "./FileInput";

export default {
  name: "JWAFilesInput",

  components: {
    JWFileInput,
  },

  props: {
    type: {
      type: Number,
      default: jwa.AFileInputType.File,
    },
    value: {
      type: Array,
      default() {
        // var obj = {
        //   Path: "",
        //   OriginPath: "",
        //   Op: "",
        //   Uploaded: false
        // };
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formIndex: {
      type: Number,
      default: -1,
    },
  },

  data() {
    return {
      inputs: null,
    };
  },

  mounted() {
    this.valueChange(this.value);
  },

  watch: {
    value(value) {
      this.valueChange(value);
    },
  },

  methods: {
    valueChange(value) {
      var inputs = [];
      jw.utils.ArrayForeach(value, function(v) {
        if (v.Op == jw.net.RemoteFileOp.None) {
          inputs.push([v]);
        }
      });
      this.inputs = inputs;
    },

    onAdd() {
      var inputs = this.inputs;
      if (!inputs) {
        inputs = [[]];
      }
      inputs.push([]);
      this.inputs = inputs;
    },

    onChange() {
      var that = this;
      var value = [];
      jw.utils.ArrayForeach(this.inputs, function(input, index) {
        var ref = that.refInput(index);
        jw.utils.ArrayForeach(ref.value, function(v) {
          value.push(v);
        });
      });
      //   this.value = value;
      this.$emit("change", {
        value: value,
        formIndex: this.formIndex,
      });
      console.debug(value);
    },

    refInput(index) {
      var ref = this.$refs["input" + index];
      if (ref) {
        if (ref[0]) {
          ref = ref[0];
        }
      }
      return ref;
    },
  },
};
</script>

<style scoped></style>
