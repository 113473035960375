import utils from "./jw.aman.utils.js";

var aman = {};

aman.ChartType = {
  Element: 0,
  Bar: 1,
};

// aman.PanelButtonRole = {
//   New: 100,
//   Mod: 200,
//   Del: 404,
//   Submit: 666,
// };
aman.PanelButtonAction = {
  Api: 0,
  Form: 100,
  Download: 200,
  CopyValue: 300,
};

aman.PanelType = {
  Table: 0,
  Calendar: 100,
  Cards: 200,
  Card: 201,
};

aman.ValueType = {
  Text: 0,
  Color: 1,
  Image: 101,
  Audio: 102,
  Video: 103,
  QRCode: 602,
};

aman.AFileInputType = {
  File: 0x1 << 0,
  Image: 0x1 << 1,
  Audio: 0x1 << 2,
  Video: 0x1 << 3,
};

aman.utils = utils;

export default aman;
