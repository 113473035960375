const time = {};

time.FromUTC = function (timeStr) {
	if (!timeStr) {
		return;
	}
	if (timeStr.constructor == Date) {
		return timeStr;
	}
	timeStr = timeStr.replace(/-/g, '/'); // NOTE iOS只能解释2019/3/18这样的日期格式，所以处理一下
	var d = new Date(timeStr + ' UTC');
	return d;
};

time.FromString = function (timeStr) {
	if (!timeStr) {
		return;
	}
	if (timeStr.constructor == Date) {
		return timeStr;
	}
	timeStr = timeStr.replace(/-/g, '/');
	var d = new Date(timeStr);
	return d;
};

time.IsLeapYear = function (year) {
	return (year % 4 == 0) && (year % 100 != 0) || (year % 400 == 0);
};

time.CheckMonth = function (month) {
	if (month < 1 || month > 12) {
		return false;
	}
	return true;
};

time.NextMonth = function (year, month) {
	var nextYear = year;
	var nextMonth = month + 1;
	if (nextMonth > 12) {
		nextYear++;
		nextMonth = 1;
	}
	return {
		Year: nextYear,
		Month: nextMonth,
	};
};

time.PrevMonth = function (year, month) {
	var prevYear = year;
	var prevMonth = month - 1;
	if (prevMonth < 1) {
		prevYear--;
		prevMonth = 12;
	}
	return {
		Year: prevYear,
		Month: prevMonth,
	};
};

time.GetDays = function (year, month) {
	switch (month) {
		case 2:
			return 28 + (time.IsLeapYear(year) ? 1 : 0);
		case 1:
		case 3:
		case 5:
		case 7:
		case 8:
		case 10:
		case 12:
			return 31;
	}
	return 30;
};

time.FormatDatetimeNumber = function (n) {
	n = n.toString()
	return n[1] ? n : '0' + n;
};

time.DateFormat = function (date, sep) {
	if (!sep) {
		sep = '-';
	}
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var day = date.getDate();
	return year + sep + this.FormatDatetimeNumber(month) + sep + this.FormatDatetimeNumber(day);
};

time.TimeFormat = function (date, sep) {
	if (!sep) {
		sep = ':';
	}
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var seconds = date.getSeconds();
	return this.FormatDatetimeNumber(hours) + sep + this.FormatDatetimeNumber(minutes) + sep + this.FormatDatetimeNumber(seconds);
};

// 【废弃】改用Countdown结合输出函数实现
time.CountDown = function (totalSeconds, sep) {
	if (!sep) {
		sep = ':';
	}
	var seconds = totalSeconds % 60;
	var minutes = totalSeconds / 60;
	var hours = minutes / 60;
	minutes = Math.floor(minutes % 60);
	hours = Math.floor(hours);

	var text = "";
	if (hours) {
		text += hours + sep;
	}
	if (minutes) {
		text += this.FormatDatetimeNumber(minutes) + sep;
	}
	return text + this.FormatDatetimeNumber(seconds);
};

time.GetCountdown = function (seconds, requireDays) {
	var countdown = {
		Seconds: 0,
		Minutes: 0,
		Hours: 0,
		Days: 0,
	};
	if (seconds <= 0) {
		return countdown;
	}
	countdown.Seconds = seconds % 60;
	countdown.Minutes = seconds / 60;
	countdown.Hours = countdown.Minutes / 60;
	countdown.Days = 0;
	countdown.Minutes = Math.floor(countdown.Minutes % 60);
	if (requireDays) {
		countdown.Days = countdown.Hours / 24;
		countdown.Days = Math.floor(countdown.Days);
		countdown.Hours = Math.floor(countdown.Hours % 24);
	} else {
		countdown.Hours = Math.floor(countdown.Hours);
	}
	return countdown;
};

time.FormatCountdown = function (countdown) {
	countdown.Seconds = this.FormatDatetimeNumber(countdown.Seconds);
	countdown.Minutes = this.FormatDatetimeNumber(countdown.Minutes);
	countdown.Hours = this.FormatDatetimeNumber(countdown.Hours);
	return countdown;
}

time.TodayZero = function () {
	var now = new Date();
	return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
};

time.GolangFixedZoneOffset = function () {
	var offset = new Date().getTimezoneOffset();
	return -offset * 60;
};

Date.prototype.AddMinutes = function (m) {
	this.setMinutes(this.getMinutes() + m);
	return this;
};
Date.prototype.Before = function (d) {
	var t1 = this.getTime();
	var t2 = d.getTime();
	return t1 < t2;
};
Date.prototype.After = function (d) {
	var t1 = this.getTime();
	var t2 = d.getTime();
	return t1 > t2;
};
Date.prototype.Equal = function (d) {
	var t1 = this.getTime();
	var t2 = d.getTime();
	return t1 == t2;
};
Date.prototype.BeforeEqual = function (d) {
	var t1 = this.getTime();
	var t2 = d.getTime();
	return t1 <= t2;
};
Date.prototype.AfterEqual = function (d) {
	var t1 = this.getTime();
	var t2 = d.getTime();
	return t1 >= t2;
};
Date.prototype.InDay = function (d) {
	var t = this.getTime();
	var s = new Date(d.getFullYear(), d.getMonth(), d.getDate());
	var e = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59);
	return s.getTime() <= t && t <= e.getTime();
};
Date.prototype.FirstDayInWeek = function () {
	var d = new Date(this.getTime());
	var weekday = d.getDay();
	d.setDate(d.getDate() - weekday);
	return d;
};
Date.prototype.LastDayInWeek = function () {
	var d = new Date(this.getTime());
	var weekday = d.getDay();
	d.setDate(d.getDate() + 6 - weekday);
	return d;
};

time.AccurateTo = {
	Year: 2,
	Month: 1,
	Day: 0,
	Hour: 0,
	Minute: 1,
	Second: 2,
};
Date.prototype.DateFormat = function (sep, accurateTo) {
	if (!sep) {
		sep = '-';
	}
	if (!accurateTo) {
		accurateTo = time.AccurateTo.Year;
	}

	var result = "";
	if (accurateTo >= time.AccurateTo.Day) {
		var day = this.getDate();
		result = time.FormatDatetimeNumber(day);
		if (accurateTo >= time.AccurateTo.Month) {
			var month = this.getMonth() + 1;
			result = time.FormatDatetimeNumber(month) + sep + result;
			if (accurateTo >= time.AccurateTo.Year) {
				var year = this.getFullYear();
				result = year + sep + result;
			}
		}
	}
	return result;
}
Date.prototype.TimeFormat = function (sep, accurateTo) {
	if (!sep) {
		sep = ':';
	}
	if (!accurateTo) {
		accurateTo = time.AccurateTo.Second;
	}

	var result = "";
	if (accurateTo >= time.AccurateTo.Hour) {
		var hours = this.getHours();
		result = time.FormatDatetimeNumber(hours);
		if (accurateTo >= time.AccurateTo.Minute) {
			var minutes = this.getMinutes();
			result += sep + time.FormatDatetimeNumber(minutes);
			if (accurateTo >= time.AccurateTo.Second) {
				var seconds = this.getSeconds();
				result += sep + time.FormatDatetimeNumber(seconds);
			}
		}
	}
	return result;
};
Date.prototype.DateTimeFormat = function (sepDate, sepTime, timeAccurateTo) {
	return this.DateFormat(sepDate) + ' ' + this.TimeFormat(sepTime, timeAccurateTo);
};

module.exports = time;