Array.prototype.LengthBy = function(by) {
  var len = 0;
  this.forEach(function(item) {
    if (by(item)) {
      len++;
    }
  });
  return len;
};
Array.prototype.Add = Array.prototype.push;
Array.prototype.AddUnique = function(item) {
  var index = this.indexOf(item);
  if (index >= 0) {
    return false;
  }
  this.push(item);
  return true;
};
Array.prototype.AddBy = function(item, by) {
  if (!by) {
    return;
  }
  if (!by(this, item)) {
    return false;
  }
  this.push(item);
  return true;
};
Array.prototype.AddAll = function(array) {
  if (!array || !array.length) {
    return;
  }
  var that = this;
  array.foreach(function(item) {
    that.push(item);
  });
};
Array.prototype.AddIf = function(item, condition) {
  if (!condition(item)) {
    return;
  }
  this.push(item);
};
Array.prototype.AddAllIf = function(array, condition) {
  if (!array || !array.length) {
    return;
  }
  var that = this;
  array.foreach(function(item) {
    if (!condition(item)) {
      return;
    }
    that.push(item);
  });
};
Array.prototype.Insert = function(index, obj) {
  this.splice(index, 0, obj);
};
Array.prototype.RemoveAtIndex = function(index) {
  if (index >= this.length) {
    return;
  }
  var removed = this[index];
  this.splice(index, 1);
  return removed;
};
Array.prototype.Remove = function(obj) {
  var index = this.indexOf(obj);
  if (index < 0) {
    return false;
  }
  this.RemoveAtIndex(index);
  return true;
};
Array.prototype.RemoveBy = function(by) {
  if (!by) {
    return;
  }
  var index = -1;
  this.each(function(item, i) {
    if (by(item)) {
      index = i;
      return false;
    }
    return true;
  });
  if (index < 0) {
    return;
  }
  this.RemoveAtIndex(index);
};
Array.prototype.Last = function() {
  return this[this.length - 1];
};
Array.prototype.RemoveFirst = function() {
  return this.RemoveAtIndex(0);
};
Array.prototype.RemoveLast = function() {
  return this.RemoveAtIndex(this.length - 1);
};
Array.prototype.Contains = function(obj) {
  return this.indexOf(obj) >= 0;
};
Array.prototype.ContainsBy = function(containItem, by) {
  if (!by) {
    return false;
  }
  var has = false;
  this.each(function(item) {
    if (by(item, containItem)) {
      has = true;
      return false;
    }
    return true;
  });
  return has;
};
Array.prototype.Clear = function() {
  this.length = 0;
};
Array.prototype.foreach = Array.prototype.forEach; // 迭代元素，不能打断（ps：我有洁癖）
Array.prototype.each = Array.prototype.every; // 迭代元素，可返回false值来停止迭代
Array.prototype.foreachr = function(onElement) {
  // 反向迭代，处理方式同foreach，onElement: function(element, [index, rIndex, array])，最后一项的rIndex为0
  if (!this.length || !onElement) {
    return;
  }
  var r = 0;
  for (var i = this.length - 1; i >= 0; i--) {
    onElement(this[i], i, r++, this);
  }
};
Array.prototype.eachr = function(onElement) {
  // 反向迭代，处理方式同each，onElement同上
  if (!this.length || !onElement) {
    return;
  }
  var i = 0;
  for (var r = this.length - 1; r >= 0; r--) {
    if (!onElement(this[r], r, i++, this)) {
      break;
    }
  }
};
Array.prototype.forrand = function(onElement) {
  if (!this.length || !onElement) {
    return;
  }

  var a = [];
  a.AddAll(this);
  var n = a.length;
  while (n > 0) {
    var r = Math.floor(Math.random() * n);
    var e = a[r];
    if (!onElement(e, r)) {
      break;
    }
    a.splice(r, 1);
    n = a.length;
  }
};
Array.prototype.walk = function(onElement) {
  if (!this.length || !onElement) {
    return;
  }
  for (var i = 0; i < this.length; i++) {
    this[i] = onElement(this[i]);
  }
};
Array.prototype.Has = function(obj) {
  return this.some(function(obj2) {
    return obj == obj2;
  });
};
Array.prototype.Find = function(keyFunc) {
  var found;
  this.every(function(obj) {
    if (keyFunc(obj)) {
      found = obj;
      return false;
    }
    return true;
  });
  return found;
};
Array.prototype.FindIndex = function(keyFunc) {
  var foundIndex = -1;
  this.every(function(obj, i) {
    if (keyFunc(obj)) {
      foundIndex = i;
      return false;
    }
    return true;
  });
  return foundIndex;
};
// Arrry.prototype.map = Array.prototype.map;
Array.prototype.Copy = function(offset, array, arrayOffset, length) {
  if (offset instanceof Array) {
    length = arrayOffset;
    arrayOffset = array;
    array = offset;
    offset = 0;
  }
  if (!arrayOffset) {
    arrayOffset = 0;
  }
  for (var i = 0; i < length; i++) {
    this[offset + i] = array[arrayOffset + i];
  }
};
Array.prototype.Equals = function(other) {
  if (this === other) {
    return true;
  }
  if (other === null) {
    return false;
  }
  if (this.length != other.length) {
    return false;
  }
  for (var i = 0; i < this.length; i++) {
    if (this[i] !== other[i]) {
      return false;
    }
  }
  return true;
};
Array.prototype.Random = function(targetSize) {
  var length = this.length;
  if (!length || !targetSize) {
    return [];
  }

  var newArray = [];
  length--;
  var i = 0;
  if (length == 0) {
    for (; i < targetSize; i++) {
      newArray.push(this[0]);
    }
  } else {
    for (; i < targetSize; i++) {
      var index = Math.round(Math.random() * length);
      newArray.push(this[index]);
    }
  }
  return newArray;
};
