<template>
  <div class="flex-column fill-height">
    <v-container>
      <v-row v-for="(row, index) in options" :key="index">
        <v-col
          v-for="(chart, index) in row"
          :key="index"
          :cols="12 / row.length"
        >
          <ECharts :options="chart" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import "../jwapp/jw.css";
import jwa from "./jw.aman.js";
import jw from "../jwapp/jw.js";

// https://echarts.apache.org/examples/zh/index.html
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";

export default {
  name: "ADashboard",

  components: {
    ECharts,
  },

  data() {
    return {
      Dashboard: {},
    };
  },

  // beforeMount() {
  //   this.doRoute(this.$route);
  // },

  mounted() {
    this.doRoute(this.$route);
  },

  watch: {
    $route(route) {
      this.doRoute(route);
    },
  },

  computed: {
    options() {
      var result = [];
      if (!this.Dashboard) {
        return result;
      }
      var barColors = ["#3ebcd2", "#e34848", "#63b35c"];
      var barColorIndex = 0;
      jw.utils.ArrayForeach(this.Dashboard.Rows, function(row) {
        var r = [];
        jw.utils.ArrayForeach(row.Charts, function(chart) {
          // var c = {
          //   grid: {
          //     left: 50,
          //     right: 0,
          //     bottom: 50,
          //   },
          // };
          var c;
          switch (chart.Type) {
            case jwa.ChartType.Element: {
              c = chart;
              break;
            }
            case jwa.ChartType.Bar: {
              c = {
                grid: {
                  left: 50,
                  right: 0,
                  bottom: 50,
                },
              };

              c.itemStyle = {
                color: barColors[barColorIndex++],
              };
              c.title = {
                text: chart.Title,
              };
              c.tooltip = {};
              c.legend = {
                data: [chart.Name],
              };

              c.xAxis = {
                type: "category",
              };
              c.yAxis = {};

              c.dataset = {};
              c.dataset.source = [];
              jw.utils.ArrayForeach(chart.Values, function(value) {
                c.dataset.source.push(value);
              });

              c.series = [];
              var cs = {
                name: chart.Name,
                type: "bar",
                encode: {
                  x: 0,
                  y: 1,
                },
              };
              c.series.push(cs);
              break;
            }
          }
          r.push(c);
        });
        result.push(r);
      });
      return result;
    },
  },

  methods: {
    doRoute(route) {
      var query = route.query;
      if (query) {
        var api = query.api;
        if (api) {
          // console.error(api);
          var client = this.$store.state.client;
          var that = this;
          client
            .Post(api)
            .then(function(res) {
              if (!res) {
                return;
              }
              that.Dashboard = res;
            })
            .catch(function(err) {
              that.$store.ShowError(err.Info);
            });
        }
      }
    },
  },
};
</script>

<style scoped>
/**
 * 默认尺寸为 600px×400px，如果想让图表响应尺寸变化，可以像下面这样
 * 把尺寸设为百分比值（同时请记得为容器设置尺寸）。
 */
.echarts {
  width: 100%;
  /* height: 100%; */
}
</style>
