<template>
  <div>
    <!-- 文本输入 -->
    <v-text-field
      v-if="item.FormType == FormType.Input"
      v-model="item.Value"
      :name="item.Name"
      :type="item.InputType ? item.InputType : 'text'"
      :placeholder="item.Placeholder"
      :suffix="item.Suffix"
      :disabled="item.Disabled"
      dense
    >
      <template v-slot:prepend>
        <v-menu v-if="item.Items && item.Items.length" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              :color="item.CheckedItem.Color"
              class="white--text"
              v-on="on"
              >{{ item.CheckedItem.Name }}</v-btn
            >
          </template>
          <v-list dense max-height="500px">
            <v-list-item
              v-for="(select_item, select_index) in item.Items"
              :key="select_index"
              :color="select_item.Color"
              style="background: #fff"
              @click="
                value[item.Name] = select_item.Id;
                item.CheckedItem = select_item;
                item.Value = select_item.Id;
              "
            >
              <v-list-item-title>{{ select_item.Name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-text-field>

    <!-- 长文本输入 -->
    <v-textarea
      v-else-if="item.FormType == FormType.TextArea"
      v-model="item.Value"
      :name="item.Name"
      :type="item.InputType ? item.InputType : 'text'"
      :placeholder="item.Placeholder"
      :suffix="item.Suffix"
      :disabled="item.Disabled"
      counter
    ></v-textarea>

    <!-- 标签输入 -->
    <JWTagInput
      v-if="item.FormType == FormType.TagInput"
      :name="item.Name"
      :value="item.Value"
      :items="item.Items"
      :title="item.Title"
      :placeholder="item.Placeholder"
      :hint="item.Hint"
      :disabled="item.Disabled"
    ></JWTagInput>

    <!-- 文件上传 -->
    <JWFileInput
      v-else-if="item.FormType == FormType.AFileInput"
      :type="item.Type"
      :disabled="item.Disabled"
      :value="item.Value"
      :preset="item.Preset"
      :max-width="item.MaxWidth"
      :max-height="item.MaxHeight"
      @change="onFileChange"
      :form-index="index"
    ></JWFileInput>

    <!-- 复数文件上传 -->
    <JWFilesInput
      v-else-if="item.FormType == FormType.AFilesInput"
      :type="item.Type"
      :disabled="item.Disabled"
      :value="item.Value"
      @change="onFilesChange"
      :form-index="index"
    ></JWFilesInput>

    <!-- 富文本输入 -->
    <quill-editor
      style="height: 600px"
      v-else-if="item.FormType == FormType.ATextEditor"
      v-model="item.Value"
      :disabled="item.Disabled"
    ></quill-editor>

    <!-- 单选 -->
    <v-menu
      v-else-if="
        item.FormType == FormType.Selector &&
        item.MaxSelect == 1 &&
        !item.Disabled
      "
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn :color="item.CheckedItem.Color" class="white--text" v-on="on">{{
          item.CheckedItem.Name
        }}</v-btn>
      </template>
      <v-list dense max-height="500px">
        <v-list-item
          v-for="(select_item, select_index) in item.Items"
          :key="select_index"
          :color="select_item.Color"
          @click="
            value[item.Name] = select_item.Id;
            item.CheckedItem = select_item;
          "
        >
          <v-list-item-title>{{ select_item.Name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      v-else-if="
        item.FormType == FormType.Selector &&
        item.MaxSelect == 1 &&
        item.Disabled
      "
      :color="item.CheckedItem.Color"
      class="white--text"
      >{{ item.CheckedItem.Name }}</v-btn
    >

    <!-- 日期选择 -->
    <v-menu
      v-else-if="item.FormType == FormType.ADatePicker"
      v-model="item.Menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="item.Value"
          :label="item.Title"
          readonly
          v-on="on"
          :disabled="item.Disabled"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="item.Value"
        @input="item.Menu = false"
        :disabled="item.Disabled"
      ></v-date-picker>
    </v-menu>

    <v-datetime-picker
      v-else-if="item.FormType == FormType.ADatetimePicker"
      :label="item.Hint ? item.Hint : item.Title"
      v-model="item.Value"
      :time-picker-props="{ format: '24hr' }"
      :disabled="item.Disabled"
    ></v-datetime-picker>

    <!-- 颜色选择 -->
    <v-menu
      v-else-if="item.FormType == FormType.AColorPicker"
      v-model="item.Menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-chip label :color="getColor" :text-color="reverseColor" v-on="on"
          >点击选择颜色</v-chip
        >
      </template>
      <v-color-picker
        v-model="item.Value"
        :disabled="item.Disabled"
      ></v-color-picker>
    </v-menu>

    <!-- <v-color-picker
      v-else-if="item.FormType == FormType.AColorPicker"
      v-model="item.Value"
      dot-size="40"
      show-swatches
      swatches-max-height="100"
      :disabled="item.Disabled"
    ></v-color-picker> -->
    <!-- 暂用文本控件代替 -->
    <!-- <v-text-field v-else-if="item.FormType == FormType.AColorPicker" v-model="item.Value"></v-text-field> -->

    <!-- <PanelMain
      :ref="'panel' + index"
      v-else-if="item.FormType == FormType.Panel"
      :panel="item"
      :ext="{ index }"
      @form="onForm"
    ></PanelMain>
    <PanelCard
      :ref="'panel' + index"
      v-else-if="item.FormType == FormType.Card"
      :card="item"
      :ext="{ index }"
      @form="onForm"
    ></PanelCard>-->
  </div>
</template>

<script>
import "quill/dist/quill.bubble.css";
// import PanelMain from "./PanelMain.vue";
// import PanelCard from "./PanelCard.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import jw from "../jwapp/jw.js";
import JWFileInput from "./FileInput";
import JWFilesInput from "./FilesInput";
import jwaui from "./jw.aman.ui.js";
import JWTagInput from "./TagInput";

export default {
  name: "JWAFormItem",

  components: {
    JWTagInput,
    JWFileInput,
    JWFilesInput,
    quillEditor,
    // PanelMain,
    // PanelCard
  },

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      FormType: jw.ui.FormType,
      value: {},
    };
  },

  computed: {
    getTime() {
      var item = this.item;
      var time = item.Value;
      if (time == "0001-01-01 00:00") {
        time = new Date().toString();
      }
      return time;
    },

    getColor() {
      var item = this.item;
      if (!item.Value) {
        return item.Value;
      }
      var val = item.Value;

      if (jw.utils.IsObject(val)) {
        val = val.hex;
      }

      return val;
    },

    reverseColor() {
      var color = this.getColor;
      if (!jw.utils.IsString(color)) {
        return "#000000";
      }

      var c = color.replace("#", "");
      var r = (c >> 16) & 0xff;
      var g = (c >> 8) & 0xff;
      var b = c & 0xff;
      r = 0xff - r;
      g = 0xff - g;
      b = 0xff - b;
      var result = "#" + r.toString(16) + g.toString(16) + b.toString(16);
      return result;
    },
  },

  methods: {
    getValue(key, formIndex, fileUploads) {
      var that = this;
      var item = this.item;
      var val = item.Value;
      switch (item.FormType) {
        case jw.ui.FormType.Input: {
          val = jwaui.form.HandleInputValue(item);
          break;
        }
        case jw.ui.FormType.Selector: {
          if (item.CheckedItem) {
            val = item.CheckedItem.Id;
          }
          break;
        }
        case jw.ui.FormType.AGroupTitle: {
          break;
        }
        case jw.ui.FormType.AFileInput:
        case jw.ui.FormType.AFilesInput: {
          jw.utils.ArrayForeach(item.Files, function (file, fileIndex) {
            var fileObj = file.File;
            if (file.Uploaded || !fileObj) {
              val = item.Value;
              return;
            }
            fileObj.formIndex = formIndex;
            fileObj.fileIndex = fileIndex;
            var upload = {
              name: "File",
              key: key,
              file: fileObj,
            };
            var client = that.$store.state.client;
            fileUploads.push(client.UploadFile("upload", upload));
          });
          break;
        }
        case jw.ui.FormType.ADatetimePicker: {
          if (val && val.DateTimeFormat) {
            val = val.DateTimeFormat("-", ":", jw.time.AccurateTo.Minute);
          }
          break;
        }
        case jw.ui.FormType.AColorPicker: {
          if (jw.utils.IsObject(val)) {
            val = val.hex;
          }
          break;
        }
      }
      return val;
    },

    onFileChange(data) {
      var item = this.item;
      item.Files = data.value;
    },

    // onForm(res) {
    //   this.$emit("form", res);
    // },

    // refPanel(index) {
    //   var ref = this.$refs["panel" + index];
    //   if (ref) {
    //     if (ref[0]) {
    //       ref = ref[0];
    //     }
    //   }
    //   return ref;
    // }
  },
};
</script>