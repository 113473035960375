require("./jw.array.js");

var utils = {};

utils.IsUndefined = function (p) {
  return typeof p === "undefined";
};
utils.IsObject = function (o) {
  return typeof o === "object" && o !== null;
};
utils.IsFunction = function (p) {
  return typeof p === "function";
};
utils.IsNumber = function (n) {
  return !isNaN(n) || n === 0;
};
utils.IsString = function (s) {
  return typeof s === "string";
};
utils.IsBool = function (p) {
  return p === true || p === false;
};
utils.IsArray = Array.isArray;
utils.ArrayIsEmpty = function (a) {
  return !a || !a.length;
};
utils.ArrayLength = function (a) {
  if (utils.ArrayIsEmpty(a)) {
    return 0;
  }
  return a.length;
};
utils.ArrayLengthBy = function (a, by) {
  if (utils.ArrayIsEmpty(a)) {
    return 0;
  }
  return a.LengthBy(by);
};
utils.ArrayForeach = function (a, f) {
  if (utils.ArrayIsEmpty(a)) {
    return;
  }
  a.foreach(f);
};
utils.ArrayEach = function (a, f) {
  if (utils.ArrayIsEmpty(a)) {
    return;
  }
  a.each(f);
};
utils.ArrayForeachr = function (a, f) {
  if (utils.ArrayIsEmpty(a)) {
    return;
  }
  a.foreachr(f);
};
utils.ArrayEachr = function (a, f) {
  if (utils.ArrayIsEmpty(a)) {
    return;
  }
  a.eachr(f);
};
utils.ArrayForrand = function (a, f) {
  if (utils.ArrayIsEmpty(a)) {
    return;
  }
  a.forrand(f);
};
utils.ArrayAdd = function (a, v) {
  if (utils.ArrayIsEmpty(a)) {
    a = [];
  }
  a.push(v);
  return a;
};
utils.ArrayAddUnique = function (a, v) {
  if (utils.ArrayIsEmpty(a)) {
    a = [];
  }
  a.AddUnique(v);
  return a;
};
utils.ArrayAddBy = function (a, v, by) {
  if (utils.ArrayIsEmpty(a)) {
    a = [];
  }
  a.AddBy(v, by);
  return a;
};
utils.ArrayContains = function (a, obj) {
  if (utils.ArrayIsEmpty(a)) {
    return;
  }
  return a.Contains(obj);
};
utils.ArrayRemove = function (a, obj) {
  if (utils.ArrayIsEmpty(a)) {
    return;
  }
  return a.Remove(obj);
};
utils.ArrayRemoveBy = function (a, by) {
  if (utils.ArrayIsEmpty(a)) {
    return;
  }
  return a.RemoveBy(by);
};
utils.ArrayAt = function (a, index, def) {
  if (index < 0) {
    return def;
  }
  var n = utils.ArrayLength(a);
  if (index >= n) {
    return def;
  }
  return a[index];
};
utils.ArraySlice = function (a, start, end) {
  if (!a) {
    return a;
  }
  return a.slice(start, end);
}
utils.ArrayFindIndex = function (a, obj) {
  if (utils.ArrayIsEmpty(a)) {
    return;
  }
  return a.FindIndex(obj);
};

utils.ParseFloat = function (string, defaultValue) {
  if (defaultValue === undefined) {
    defaultValue = 0;
  }
  var f = parseFloat(string);
  if (isNaN(f)) {
    return defaultValue;
  }
  return f;
};

utils.ParseInt = function (string, defaultValue) {
  if (defaultValue === undefined) {
    defaultValue = 0;
  }
  var i = parseInt(string, 10);
  if (isNaN(i)) {
    return defaultValue;
  }
  return i;
};

utils.ParseIntHex = function (string, defaultValue) {
  if (defaultValue === undefined) {
    defaultValue = 0;
  }
  var i = parseInt(string, 16);
  if (isNaN(i)) {
    return defaultValue;
  }
  return i;
};

utils.CheckPhone = function (phone) {
  return /^1[3|4|5|8][0-9]\d{4,8}$/.test(phone);
};

utils.Clone = function (obj) {
  if (utils.IsArray(obj)) {
    res = [];
    obj.foreach(function (item) {
      res.push(utils.Clone(item));
    });
    return res;
  }
  if (!obj || !utils.IsObject(obj)) {
    return obj;
  }
  var res = {};
  // var props = Object.getOwnPropertyNames(obj);
  // props.forEach(function(prop) {
  //   res[prop] = utils.Clone(obj[prop]);
  // });
  // NOTE Object.getOwnPropertyNames同时会返回不可枚举的属性名，故在复制过程中可能会出现递归嵌套或者大量冗余数据的情况，故使用迭代属性替代
  for (var prop in obj) {
    res[prop] = utils.Clone(obj[prop]);
  }
  return res;
};
utils.New = function (obj) {
  obj = utils.Clone(obj);
  if (!obj) {
    obj = {};
  }
  return obj;
};
utils.Copy = function (fromObj, toObj, skipNull) {
  if (!fromObj && fromObj !== 0 && fromObj !== "") {
    return toObj;
  }
  if (!utils.IsObject(fromObj)) {
    return fromObj;
  }
  if (utils.IsArray(fromObj)) {
    toObj = [];
    fromObj.foreach(function (item) {
      item = utils.Copy(item);
      if (skipNull && !item) {
        return;
      }
      toObj.push(utils.Copy(item));
    });
    return toObj;
  }
  if (!toObj || !utils.IsObject(toObj)) {
    toObj = {};
  }
  // var props = Object.getOwnPropertyNames(fromObj);
  // props.forEach(function(prop) {
  //   toObj[prop] = utils.Copy(fromObj[prop]);
  // });
  // NOTE Object.getOwnPropertyNames同时会返回不可枚举的属性名，故在复制过程中可能会出现递归嵌套或者大量冗余数据的情况，故使用迭代属性替代
  for (var prop in fromObj) {
    var fromProp = fromObj[prop];
    if (skipNull && !fromProp) {
      continue;
    }
    toObj[prop] = utils.Copy(fromProp);
  }
  return toObj;
};

utils.Base = function (str) {
  var base = new String(str).substring(str.lastIndexOf("/") + 1);
  if (base.lastIndexOf(".") != -1)
    base = base.substring(0, base.lastIndexOf("."));
  return base;
};

utils.FlagsAdd = function (flags, addition) {
  return flags | addition;
};

utils.FlagsRemove = function (flags, removal) {
  return flags & ~removal;
};

utils.FlagsSet = function (flags, offset, value) {
  var op = 0x1 << offset;
  if (value) {
    return utils.FlagsAdd(flags, op);
  } else {
    return utils.FlagsRemove(flags, op);
  }
};

utils.FlagsCheck = function (flags, test) {
  return (flags & test) != 0;
};

utils.FlagsAll = function (maxOffset) {
  return (0x1 << (maxOffset + 1)) - 1;
};

utils.Random = function (min, max) {
  var r = Math.random();
  r = min + (max - min) * r;
  return r;
};

// module.exports = utils;
export default utils;
