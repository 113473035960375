var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item.FormType == _vm.FormType.Input)?_c('v-text-field',{attrs:{"name":_vm.item.Name,"type":_vm.item.InputType ? _vm.item.InputType : 'text',"placeholder":_vm.item.Placeholder,"suffix":_vm.item.Suffix,"disabled":_vm.item.Disabled,"dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(_vm.item.Items && _vm.item.Items.length)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text",attrs:{"color":_vm.item.CheckedItem.Color}},on),[_vm._v(_vm._s(_vm.item.CheckedItem.Name))])]}}],null,false,2422896043)},[_c('v-list',{attrs:{"dense":"","max-height":"500px"}},_vm._l((_vm.item.Items),function(select_item,select_index){return _c('v-list-item',{key:select_index,staticStyle:{"background":"#fff"},attrs:{"color":select_item.Color},on:{"click":function($event){_vm.value[_vm.item.Name] = select_item.Id;
              _vm.item.CheckedItem = select_item;
              _vm.item.Value = select_item.Id;}}},[_c('v-list-item-title',[_vm._v(_vm._s(select_item.Name))])],1)}),1)],1):_vm._e()]},proxy:true}],null,false,2565393932),model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}}):(_vm.item.FormType == _vm.FormType.TextArea)?_c('v-textarea',{attrs:{"name":_vm.item.Name,"type":_vm.item.InputType ? _vm.item.InputType : 'text',"placeholder":_vm.item.Placeholder,"suffix":_vm.item.Suffix,"disabled":_vm.item.Disabled,"counter":""},model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}}):_vm._e(),(_vm.item.FormType == _vm.FormType.TagInput)?_c('JWTagInput',{attrs:{"name":_vm.item.Name,"value":_vm.item.Value,"items":_vm.item.Items,"title":_vm.item.Title,"placeholder":_vm.item.Placeholder,"hint":_vm.item.Hint,"disabled":_vm.item.Disabled}}):(_vm.item.FormType == _vm.FormType.AFileInput)?_c('JWFileInput',{attrs:{"type":_vm.item.Type,"disabled":_vm.item.Disabled,"value":_vm.item.Value,"preset":_vm.item.Preset,"max-width":_vm.item.MaxWidth,"max-height":_vm.item.MaxHeight,"form-index":_vm.index},on:{"change":_vm.onFileChange}}):(_vm.item.FormType == _vm.FormType.AFilesInput)?_c('JWFilesInput',{attrs:{"type":_vm.item.Type,"disabled":_vm.item.Disabled,"value":_vm.item.Value,"form-index":_vm.index},on:{"change":_vm.onFilesChange}}):(_vm.item.FormType == _vm.FormType.ATextEditor)?_c('quill-editor',{staticStyle:{"height":"600px"},attrs:{"disabled":_vm.item.Disabled},model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}}):(
      _vm.item.FormType == _vm.FormType.Selector &&
      _vm.item.MaxSelect == 1 &&
      !_vm.item.Disabled
    )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text",attrs:{"color":_vm.item.CheckedItem.Color}},on),[_vm._v(_vm._s(_vm.item.CheckedItem.Name))])]}}])},[_c('v-list',{attrs:{"dense":"","max-height":"500px"}},_vm._l((_vm.item.Items),function(select_item,select_index){return _c('v-list-item',{key:select_index,attrs:{"color":select_item.Color},on:{"click":function($event){_vm.value[_vm.item.Name] = select_item.Id;
          _vm.item.CheckedItem = select_item;}}},[_c('v-list-item-title',[_vm._v(_vm._s(select_item.Name))])],1)}),1)],1):(
      _vm.item.FormType == _vm.FormType.Selector &&
      _vm.item.MaxSelect == 1 &&
      _vm.item.Disabled
    )?_c('v-btn',{staticClass:"white--text",attrs:{"color":_vm.item.CheckedItem.Color}},[_vm._v(_vm._s(_vm.item.CheckedItem.Name))]):(_vm.item.FormType == _vm.FormType.ADatePicker)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.item.Title,"readonly":"","disabled":_vm.item.Disabled},model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}},on))]}}]),model:{value:(_vm.item.Menu),callback:function ($$v) {_vm.$set(_vm.item, "Menu", $$v)},expression:"item.Menu"}},[_c('v-date-picker',{attrs:{"disabled":_vm.item.Disabled},on:{"input":function($event){_vm.item.Menu = false}},model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}})],1):(_vm.item.FormType == _vm.FormType.ADatetimePicker)?_c('v-datetime-picker',{attrs:{"label":_vm.item.Hint ? _vm.item.Hint : _vm.item.Title,"time-picker-props":{ format: '24hr' },"disabled":_vm.item.Disabled},model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}}):(_vm.item.FormType == _vm.FormType.AColorPicker)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","color":_vm.getColor,"text-color":_vm.reverseColor}},on),[_vm._v("点击选择颜色")])]}}]),model:{value:(_vm.item.Menu),callback:function ($$v) {_vm.$set(_vm.item, "Menu", $$v)},expression:"item.Menu"}},[_c('v-color-picker',{attrs:{"disabled":_vm.item.Disabled},model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }