<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="grey darken-2" dark flat>
                <v-toolbar-title>
                  {{
                  $vuetify.lang.t("$vuetify.AppTitle")
                  }}
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    id="Account"
                    name="Account"
                    :label="$vuetify.lang.t('$vuetify.Account.Label')"
                    v-model="User.Account"
                    :rules="ruleAccount"
                    type="text"
                    clearable
                    required
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    name="Password"
                    :label="$vuetify.lang.t('$vuetify.Password.Label')"
                    v-model="User.Password"
                    :rules="rulePassword"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    clearable
                    counter
                    required
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  small
                  @click="onPasswordModity"
                >{{ $vuetify.lang.t("$vuetify.PasswordModity") }}</v-btn>
                <v-btn
                  :disabled="!valid"
                  color="blue darken-1"
                  class="white--text mr-4"
                  @click="onSubmit"
                >{{ $vuetify.lang.t("$vuetify.Login") }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-alert :value="err.show" type="error">{{ err.info }}</v-alert>
  </v-app>
</template>

<script>
import md5 from "js-md5";
import jw from "../jwapp/jw.js";
import jwa from "./jw.aman.js";

export default {
  name: "JWALogin",

  data() {
    return {
      valid: false,
      ruleAccount: [v => !!v || "请填写账号"],
      showPassword: false,
      rulePassword: [v => !!v || "请填写密码"],
      User: {
        Account: "",
        Password: ""
      },
      err: {
        show: false,
        info: ""
      }
    };
  },

  methods: {
    onPasswordModity() {
      var pages = this.$store.state.pages;
      var router = this.$router;
      router.push(jwa.utils.RouterPath(router, pages.passmod));
    },

    onSubmit() {
      // console.log(this.User);
      var client = this.$store.state.client;
      var lang = this.$route.query.lang;
      // console.debug("setLang:", lang);
      // this.$store.commit("setLang", lang);

      // var key = {
      //   Account: this.User.Account,
      //   Password: this.User.Password
      // };
      var key = jw.utils.New(this.User);
      if (key.Password) {
        key.Password = md5(key.Password);
      }
      var that = this;
      client
        .Post("login", {
          lang: lang,
          data: {
            Key: key
          }
        })
        .then(function() {
          that.$refs.form.reset();
          // that.$router.push({
          //   path: "/aman",
          //   // query: {
          //   //   lang: lang,
          //   // },
          // });
          var pages = that.$store.state.pages;
          that.$router.push(jwa.utils.RouterPath(that.$router, pages.aman));
        })
        .catch(function(err) {
          console.error(err);
          jwa.utils.ShowError(err, that);
        });
    }
  }
};
</script>
