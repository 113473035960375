import "./jw.array.js";

// ---------------------------------------------------------------------------
import data from "./jw.data.js";

// ---------------------------------------------------------------------------
import strings from "./jw.strings.js";

// ---------------------------------------------------------------------------
import json from "./jw.json.js";

// ---------------------------------------------------------------------------
import utils from "./jw.utils.js";

// ---------------------------------------------------------------------------
import time from "./jw.time.js";

// // ---------------------------------------------------------------------------
// // 并非dom操作，只是方便改变界面数据的函数
// const dom = {};
// dom.IndexOfClass = function (originClass, className) {
// 	if (!className || !originClass) {
// 		return -1;
// 	}
// 	var index = originClass.indexOf(className);
// 	if (index == -1) {
// 		return -1;
// 	}
// 	var prevIndex = index - 1;
// 	var nextIndex = index + className.length;
// 	var prevChar = ' ';
// 	var nextChar = ' ';
// 	if (prevIndex >= 0) {
// 		prevChar = originClass[prevIndex];
// 	}
// 	if (nextIndex < originClass.length) {
// 		nextChar = originClass[nextIndex];
// 	}
// 	if (prevChar != ' ' || nextChar != ' ') {
// 		return -1;
// 	}
// 	return index;
// };

// dom.HasClass = function (originClass, className) {
// 	return dom.IndexOfClass(originClass, className) >= 0;
// };

// dom.AddClass = function (originClass, className) {
// 	if (!className) {
// 		return originClass;
// 	}
// 	className = className.trim();
// 	if (dom.HasClass(originClass, className)) {
// 		return originClass;
// 	}
// 	if (originClass && originClass.length) {
// 		originClass = originClass.trim() + ' ' + className;
// 	} else {
// 		originClass = className;
// 	}
// 	return originClass;
// };

// dom.ReplaceClass = function (originClass, fromClass, toClass) {
// 	if (!fromClass) {
// 		return faoriginClasslse;
// 	}
// 	fromClass = fromClass.trim();
// 	var index = dom.IndexOfClass(originClass, fromClass);
// 	if (index == -1) {
// 		return originClass;
// 	}
// 	var startClass = originClass.substring(0, index);
// 	var endClass = originClass.substring(index + fromClass.length);
// 	if (toClass) {
// 		toClass = toClass.trim();
// 		originClass = startClass + toClass + endClass;
// 		return originClass;
// 	}
// 	originClass = startClass + endClass;
// 	return originClass;
// };

// dom.RemoveClass = function (originClass, className) {
// 	return dom.ReplaceClass(originClass, className);
// };

// ---------------------------------------------------------------------------
import net from "./jw.net.js";

// ---------------------------------------------------------------------------
// const wxx = require('./jw.wxx.js');

// ---------------------------------------------------------------------------
// const app = {};
// app.IsIphoneX = function (model) {
// 	return model.search(/iphone x/i) >= 0;
// };

// function appInitResult(appObj) {
// 	return {
// 		systemInfo: appObj.systemInfo,
// 		mbbcr: appObj.menuButtonBoundingClientRect ? appObj.menuButtonBoundingClientRect : "null",
// 		gap: appObj.gap,
// 		navbarHeight: appObj.navbarHeight,
// 		navbarHeightRpx: appObj.navbarHeightRpx,
// 		paddingBottom: appObj.paddingBottom,
// 		paddingBottomRpx: appObj.paddingBottomRpx,
// 		heightExceptBars: appObj.heightExceptBars,
// 		heightExceptBarsRpx: appObj.heightExceptBarsRpx,
// 	};
// }

// // app.init = function (appObj, systemInfo) {
// // 	var mbbcr = wx.getMenuButtonBoundingClientRect();

// // 	appObj.menuButtonBoundingClientRect = mbbcr;
// // 	appObj.screenWidth = systemInfo.screenWidth;
// // 	appObj.screenHeight = systemInfo.screenHeight;

// // 	var rpx = 750 / systemInfo.screenWidth;
// // 	var px = systemInfo.screenWidth / 750;
// // 	appObj.Rpx = rpx;
// // 	appObj.Px = px;

// // 	var statusBarHeight = systemInfo.statusBarHeight;
// // 	var paddingTop = statusBarHeight;
// // 	if (!statusBarHeight) { // NOTE 某些手机statusBarHeight返回0，同时渲染页面会从statusBar下部开始，故先这样处理
// // 		statusBarHeight = 20;
// // 		paddingTop = 0;
// // 	}
// // 	var gap = mbbcr.top - statusBarHeight;
// // 	appObj.statusBarHeight = statusBarHeight;
// // 	appObj.statusBarHeightRpx = statusBarHeight * rpx;
// // 	appObj.navbarHeight = mbbcr.bottom + gap + 10;
// // 	appObj.navbarHeightRpx = appObj.navbarHeight * rpx;
// // 	appObj.navbar = function (ext) {
// // 		var result = {
// // 			paddingTop: paddingTop,
// // 			paddingTopRpx: appObj.statusBarHeightRpx,
// // 			height: appObj.navbarHeight,
// // 			heightRpx: appObj.navbarHeightRpx,
// // 		};
// // 		if (ext) {
// // 			utils.Copy(ext, result);
// // 		}
// // 		return result;
// // 	};

// // 	// NOTE tabbar高度这里设置（写死）
// // 	appObj.tabbarHeightRpx = 90;
// // 	appObj.tabbarHeight = appObj.tabbarHeightRpx * px;
// // 	var tabbarData = {};
// // 	tabbarData.height = appObj.tabbarHeight;
// // 	tabbarData.paddingTopRpx = 12;
// // 	tabbarData.paddingTop = tabbarData.paddingTopRpx * px;
// // 	// NOTE iphone X适配
// // 	if (app.IsIphoneX(systemInfo.model)) {
// // 		if (!systemInfo.pixelRatio) {
// // 			systemInfo.pixelRatio = 1;
// // 		}
// // 		// 底部操作条区域的高度为34pt
// // 		appObj.paddingBottom = 34 / systemInfo.pixelRatio;
// // 		tabbarData.paddingBottom = 6 + appObj.paddingBottom;
// // 	} else {
// // 		appObj.paddingBottom = 0;
// // 		tabbarData.paddingBottom = 6;
// // 	}
// // 	appObj.paddingBottomRpx = appObj.paddingBottom * rpx;

// // 	// tabbar数据对象
// // 	var tabbar = appObj.tabbar;
// // 	appObj.tabbar = function (ext) {
// // 		var result = {};
// // 		utils.Copy(tabbarData, result);
// // 		utils.Copy(tabbar, result);
// // 		if (ext) {
// // 			utils.Copy(ext, result);
// // 		}
// // 		return result;
// // 	};

// // 	// 自适应后的高度值
// // 	appObj.tabbarHeight += tabbarData.paddingTop + tabbarData.paddingBottom;
// // 	appObj.tabbarHeightRpx = appObj.tabbarHeight * rpx;

// // 	// appObj.heightExceptStatusBar = systemInfo.screenHeight - appObj.statusBarHeight;
// // 	// appObj.heightExceptStatusBarRpx = appObj.heightExceptStatusBar * rpx;
// // 	appObj.titleBarHeight = /** appObj.statusBarHeight + **/ appObj.navbarHeight;
// // 	appObj.titleBarHeightRpx = appObj.titleBarHeight * rpx;
// // 	appObj.heightExceptTitleBars = systemInfo.screenHeight - appObj.titleBarHeight;
// // 	appObj.heightExceptTitleBarsRpx = appObj.heightExceptTitleBars * rpx;
// // 	appObj.heightExceptBars = systemInfo.screenHeight /**- appObj.statusBarHeight**/ - appObj.tabbarHeight - appObj.navbarHeight;
// // 	appObj.heightExceptBarsRpx = appObj.heightExceptBars * rpx;

// // 	wxx.AppVersion = appObj.AppVersion;
// // 	// console.debug(systemInfo);
// // 	// console.debug({
// // 	//     gap: gap,
// // 	//     navbarHeight: appObj.navbarHeight,
// // 	//     tabbarHeight: appObj.tabbarHeight,
// // 	//     mbbcr: mbbcr,
// // 	// });

// // 	appObj.gap = gap;

// // 	appObj.__initDone = true;

// // 	return appInitResult(app);
// // };

// app.init = function (appObj, systemInfo, mbbcr) {
// 	// var mbbcr = wx.getMenuButtonBoundingClientRect();

// 	appObj.platform = systemInfo.platform;
// 	appObj.system = systemInfo.system;
// 	appObj.menuButtonBoundingClientRect = mbbcr;
// 	appObj.systemInfo = systemInfo;
// 	appObj.screenWidth = systemInfo.screenWidth;
// 	appObj.screenHeight = systemInfo.screenHeight;

// 	var rpx = 750 / systemInfo.screenWidth;
// 	var px = systemInfo.screenWidth / 750;
// 	appObj.Rpx = rpx;
// 	appObj.Px = px;

// 	var statusBarHeight = systemInfo.statusBarHeight;
// 	var paddingTop = statusBarHeight;
// 	if (!statusBarHeight) { // NOTE 某些手机statusBarHeight返回0，同时渲染页面会从statusBar下部开始，故先这样处理
// 		statusBarHeight = 20;
// 		paddingTop = 0;
// 	}
// 	var gap = mbbcr.top - statusBarHeight;
// 	appObj.statusBarHeight = statusBarHeight;
// 	appObj.statusBarHeightRpx = statusBarHeight * rpx;
// 	appObj.navbarHeight = mbbcr.bottom + gap + 10;
// 	appObj.navbarHeightRpx = appObj.navbarHeight * rpx;
// 	appObj.navbar = function (ext) {
// 		var result = {
// 			paddingTop: paddingTop,
// 			paddingTopRpx: appObj.statusBarHeightRpx,
// 			height: appObj.navbarHeight,
// 			heightRpx: appObj.navbarHeightRpx,
// 		};
// 		if (ext) {
// 			utils.Copy(ext, result);
// 		}
// 		return result;
// 	};

// 	// NOTE tabbar高度这里设置（写死）
// 	appObj.tabbarHeightRpx = 90;
// 	appObj.tabbarHeight = appObj.tabbarHeightRpx * px;
// 	var tabbarData = {};
// 	tabbarData.height = appObj.tabbarHeight;
// 	tabbarData.paddingTopRpx = 12;
// 	tabbarData.paddingTop = tabbarData.paddingTopRpx * px;
// 	// NOTE iphone X适配
// 	if (app.IsIphoneX(systemInfo.model)) {
// 		if (!systemInfo.pixelRatio) {
// 			systemInfo.pixelRatio = 1;
// 		}
// 		// 底部操作条区域的高度为34pt
// 		appObj.paddingBottom = 34 / systemInfo.pixelRatio;
// 		tabbarData.paddingBottom = 6 + appObj.paddingBottom;
// 	} else {
// 		appObj.paddingBottom = 0;
// 		tabbarData.paddingBottom = 6;
// 	}
// 	appObj.paddingBottomRpx = appObj.paddingBottom * rpx;

// 	// tabbar数据对象
// 	// var tabbar = appObj.tabbar;
// 	// appObj.tabbar = function (ext) {
// 	// 	var result = {};
// 	// 	utils.Copy(tabbarData, result);
// 	// 	utils.Copy(tabbar, result);
// 	// 	if (ext) {
// 	// 		utils.Copy(ext, result);
// 	// 	}
// 	// 	return result;
// 	// };
// 	// NOTE 无需使用模板及以上函数辅助生成tabbar了，改为将数据返回，使用自定义tabbar
// 	appObj.tabbarData = tabbarData;

// 	// 自适应后的高度值
// 	appObj.tabbarHeight += tabbarData.paddingTop + tabbarData.paddingBottom;
// 	appObj.tabbarHeightRpx = appObj.tabbarHeight * rpx;

// 	// appObj.heightExceptStatusBar = systemInfo.screenHeight - appObj.statusBarHeight;
// 	// appObj.heightExceptStatusBarRpx = appObj.heightExceptStatusBar * rpx;
// 	appObj.titleBarHeight = /** appObj.statusBarHeight + **/ appObj.navbarHeight;
// 	appObj.titleBarHeightRpx = appObj.titleBarHeight * rpx;
// 	appObj.heightExceptTitleBars = systemInfo.screenHeight - appObj.titleBarHeight;
// 	appObj.heightExceptTitleBarsRpx = appObj.heightExceptTitleBars * rpx;
// 	appObj.heightExceptBars = systemInfo.screenHeight /**- appObj.statusBarHeight**/ - appObj.tabbarHeight - appObj.navbarHeight;
// 	appObj.heightExceptBarsRpx = appObj.heightExceptBars * rpx;

// 	wxx.AppVersion = appObj.AppVersion;
// 	// console.debug(systemInfo);
// 	// console.debug({
// 	//     gap: gap,
// 	//     navbarHeight: appObj.navbarHeight,
// 	//     tabbarHeight: appObj.tabbarHeight,
// 	//     mbbcr: mbbcr,
// 	// });

// 	appObj.gap = gap;

// 	appObj.__initDone = true;

// 	return appInitResult(appObj);
// };

// app.doInit = function (appObj, systemInfo, mbbcr, onDone) {
// 	var result = app.init(appObj, systemInfo, mbbcr);
// 	console.debug('[app init] end');
// 	if (onDone) {
// 		onDone(result);
// 	}
// 	if (appObj.__initOnDones) {
// 		appObj.__initOnDones.foreach(function (onDone, i) {
// 			// wx.showToast({
// 			//   title: 'onDone',
// 			// })
// 			onDone(result);
// 		});
// 		delete appObj.__initOnDones;
// 	}
// }

// app.InitFunc = function (appObj) {
// 	appObj.RefreshPage = function (willDo) {
// 		if (this.__refresh_page) {
// 			if (willDo) {
// 				this.__refresh_page = willDo();
// 			} else {
// 				this.__refresh_page = false;
// 			}
// 		}
// 		return false;
// 	};
// 	appObj.WillRefresh = function (tab) {
// 		appObj.__refresh_page = true;
// 	};
// 	appObj.RefreshTab = function (clear) {
// 		if (!appObj.__refresh_page_tab) {
// 			return 0;
// 		}
// 		var tab = appObj.__refresh_page_tab;
// 		if (clear) {
// 			appObj.__refresh_page_tab = 0;
// 		}
// 		return tab;
// 	};
// 	appObj.WillRefreshTab = function (tab) {
// 		if (utils.IsUndefined(tab)) {
// 			tab = 0;
// 		}
// 		appObj.__refresh_page_tab = tab;
// 	};

// 	appObj.switchTab = function (e) {
// 		wx.reLaunch({
// 			url: e.currentTarget.dataset.path,
// 		});
// 	};
// 	appObj.onBack = function (url) {
// 		return function () {
// 			// var lookback = appObj.__lookback;
// 			// if (lookback) {
// 			// 	var curPage = wxx.CurrentPage();
// 			// 	var absRoute = '/' + curPage.route;
// 			// 	if (absRoute == lookback.to) {
// 			// 		console.debug("执行回溯：", absRoute, " -> ", lookback.from);
// 			// 		wxx.NavigateBack({
// 			// 			url: url,
// 			// 			to: lookback.from,
// 			// 		});
// 			// 		delete appObj.__lookback;
// 			// 	}
// 			// }
// 			wxx.NavigateBack({
// 				url: url,
// 			});
// 		}
// 	};
// 	// appObj.LookBack = function(to) {
// 	// 	var curPage = wxx.CurrentPage();
// 	// 	var absRoute = '/' + curPage.route;
// 	// 	console.debug("设置回溯：", absRoute, " -> ", to);
// 	// 	appObj.__lookback = {
// 	// 		from: absRoute,
// 	// 		to: to,
// 	// 	};
// 	// };

// 	// NOTE 这里预先初始化，避免某些页面没有修改自适应机制而出现navbar为null的错误
// 	appObj.navbar = function (ext) {
// 		ext.paddingTopRpx = 22;
// 		ext.heightRpx = 49;
// 		return ext;
// 	};
// };

// app.Init = function (appObj, onDone, tag) {
// 	// wx.getSystemInfo({
// 	//     success: function (res) {
// 	//         console.debug(res);
// 	//         app.init(appObj, res);
// 	//         if (onDone) {
// 	//             onDone();
// 	//         }
// 	//     },
// 	//     fail: function (res) {
// 	//         console.error(res);
// 	//         wxx.ShowError('初始化失败，请重启小程序');
// 	//     },
// 	// });
// 	// console.debug("navbarHeight:" + appObj.navbarHeight);
// 	if (appObj.__initDone) {
// 		if (onDone) {
// 			// wx.showToast({
// 			//   title: 'onDone',
// 			// });
// 			onDone();
// 		}
// 		return;
// 	}
// 	// try {
// 	//     var res = wx.getSystemInfoSync();
// 	//     console.debug('[app init] start');
// 	//     app.init(appObj, res);
// 	//     console.debug('[app init] end');
// 	//     if (onDone) {
// 	//     	onDone();
// 	//     }
// 	// } catch (e) {
// 	//     console.error(e);
// 	//     wxx.ShowError(e);
// 	// }
// 	if (appObj.__initDoing && onDone) {
// 		if (!appObj.__initOnDones) {
// 			appObj.__initOnDones = [];
// 		}
// 		appObj.__initOnDones.Add(onDone);
// 		return true;
// 	}
// 	appObj.__initDoing = true;
// 	wx.getSystemInfo({
// 		success: function (res) {
// 			console.debug('[app init] start');
// 			var mbbcr = wx.getMenuButtonBoundingClientRect();
// 			if (!mbbcr.bottom || !mbbcr.height || !mbbcr.left || !mbbcr.right || !mbbcr.top || !mbbcr.width) {
// 				setTimeout(function () {
// 					var mbbcr = wx.getMenuButtonBoundingClientRect();
// 					app.doInit(appObj, res, mbbcr, onDone);
// 				}, 1000);
// 			} else {
// 				app.doInit(appObj, res, mbbcr, onDone);
// 			}
// 		},
// 		fail: function (e) {
// 			jw.wxx.ShowError(e);
// 		},
// 	});
// 	// var systemInfo = {
// 	// 	"SDKVersion": "2.8.3",
// 	// 	"albumAuthorized": true,
// 	// 	"batteryLevel": 87,
// 	// 	"bluetoothEnabled": true,
// 	// 	"brand": "iPhone",
// 	// 	"cameraAuthorized": true,
// 	// 	"deviceOrientation": "portrait",
// 	// 	"errMsg": "getSystemInfo:ok",
// 	// 	"fontSizeSetting": 17,
// 	// 	"language": "zh_CN",
// 	// 	"locationAuthorized": true,
// 	// 	"locationEnabled": true,
// 	// 	"microphoneAuthorized": true,
// 	// 	"model": "iPhone XR\u003ciPhone11,8\u003e",
// 	// 	"notificationAlertAuthorized": true,
// 	// 	"notificationAuthorized": true,
// 	// 	"notificationBadgeAuthorized": true,
// 	// 	"notificationSoundAuthorized": true,
// 	// 	"pixelRatio": 2,
// 	// 	"platform": "ios",
// 	// 	"safeArea": {
// 	// 		"bottom": 862,
// 	// 		"height": 818,
// 	// 		"left": 0,
// 	// 		"right": 414,
// 	// 		"top": 44,
// 	// 		"width": 414
// 	// 	},
// 	// 	"screenHeight": 896,
// 	// 	"screenWidth": 414,
// 	// 	"statusBarHeight": 44,
// 	// 	"system": "iOS 12.4.1",
// 	// 	"version": "7.0.5",
// 	// 	"wifiEnabled": true,
// 	// 	"windowHeight": 896,
// 	// 	"windowWidth": 414
// 	// };
// 	// var mbbcr = {
// 	// 	"bottom": 80,
// 	// 	"height": 32,
// 	// 	"left": 320,
// 	// 	"right": 407,
// 	// 	"top": 48,
// 	// 	"width": 87
// 	// };
// 	// app.doInit(appObj, systemInfo, mbbcr, onDone);
// };

// // NOTE 确保前端token有效后，才执行onLogin
// app.MakesureLogin = function (appObj, onLogin) {
// 	// var user = wxx.GetUser();
// 	// var token = wxx.GetToken();
// 	// var notLogin = !token || !token.Code;
// 	// if (notLogin) {
// 	// 	wxx.ShowLoading();
// 	// 	app.LoginWX(appObj, {
// 	// 		success: function (user, token) {
// 	// 			!onLogin || onLogin(user, token);
// 	// 		},
// 	// 	});
// 	// } else {
// 	// 	!onLogin || onLogin(user, token);
// 	// }
// 	var user = {}; // 【兼容处理】假user
// 	var token = net.GetToken();
// 	if (!token) {
// 		wxx.ShowLoading();
// 		app.LoginWX(appObj, {
// 			success: function (user, token) {
// 				!onLogin || onLogin(user, token);
// 			},
// 		});
// 	} else {
// 		!onLogin || onLogin(user, token);
// 	}
// };

// app.LoginWX = function (appObj, params) {
// 	var api = appObj.LoginApi;
// 	if (!api) {
// 		api = params.loginApi;
// 	}
// 	var userInfo = params.userInfo;
// 	var phone = params.phone;
// 	var code = params.code;
// 	var success = params.success;
// 	var failed = params.failed;

// 	var client = appObj.Client;
// 	var key = {
// 		Code: code,
// 	};
// 	if (userInfo) {
// 		key.Name = userInfo.nickName;
// 		key.Avatar = userInfo.avatarUrl;
// 		key.Gender = userInfo.gender;
// 	}
// 	if (phone) {
// 		key.encryptedData = phone.encryptedData;
// 		key.iv = phone.iv;
// 	}

// 	console.debug('微信登录');
// 	var token = wxx.GetToken();
// 	client.Post(api, {
// 		data: {
// 			Key: key,
// 			Token: token,
// 		},
// 		success: function (user, token) {
// 			wxx.SetLoginData(user, token);
// 			console.debug('微信登录成功');
// 			if (success) {
// 				success(user, token);
// 			}
// 		},
// 		failed: function (err) {
// 			if (err.Code == net.Code.LoginWXMissingCode) {
// 				console.debug('微信登录失败，需要code换取openid');
// 				app.AuthLoginWX(appObj, params);
// 				return;
// 			}
// 			// wx.hideLoading();
// 			console.error('微信登录失败');
// 			// wxx.ShowError(err.Info);
// 			wxx.ShowError('【微信登录】连接错误(lwx)，原因：', err.Info);
// 			if (failed) {
// 				failed();
// 			}
// 		},
// 	}, true);
// };

// app.AuthLoginWX = function (appObj, params) {
// 	if (!params) {
// 		params = {};
// 	}
// 	var failed = params.failed;
// 	wx.login({
// 		success: function (res) {
// 			var code = res.code;
// 			// console.debug('获取code成功');
// 			if (code) {
// 				params.code = code;
// 				app.LoginWX(appObj, params);
// 			} else {
// 				wxx.ShowError("【微信登录】连接错误(alwx)，原因：", res.errMsg);
// 			}
// 		},
// 		fail: function (res) {
// 			// console.error('微信登录失败');
// 			var errMsg;
// 			if (res) {
// 				errMsg = res.errMsg;
// 			}
// 			wxx.ShowError("【微信登录】连接错误(alwx)，原因：", errMsg);
// 			if (failed) {
// 				failed(res);
// 			}
// 		},
// 	});
// };

// app.LoginFirstHandler = function (appObj) {
// 	if (!appObj.LoginApi) {
// 		console.error("请设置登录Api");
// 		return;
// 	}
// 	return function (onDone) {
// 		wxx.ShowLoading('加载中');
// 		app.AuthLoginWX(appObj, {
// 			success: function (user, token) {
// 				// wxx.SetLoginData(user, token);
// 				wxx.HideLoading();
// 				onDone();
// 			},
// 			failed: function (err) {
// 				wxx.HideLoading();
// 				// wxx.ShowError('连接错误(lfh)，原因：', err);
// 			},
// 		});
// 	};
// }

// app.AuthFirstHandler = function (appObj) {
// 	if (!appObj.AuthPage) {
// 		console.error("请设置授权页面");
// 		return;
// 	}
// 	return function (err) {
// 		var page = wxx.CurrentPage();
// 		console.debug("当前页面：" + page.route);
// 		var absRoute = '/' + page.route;
// 		if (absRoute != appObj.AuthPage && page.route != appObj.AuthPage) {
// 			var opentype;
// 			switch (err.Code) {
// 				case net.Code.AuthFirstWXUserInfo: {
// 					opentype = 'getUserInfo';
// 					break;
// 				}
// 				case net.Code.AuthFirstWXPhone: {
// 					opentype = 'getPhoneNumber';
// 					break;
// 				}
// 			}
// 			wx.showModal({
// 				title: '提示',
// 				// content: '授权信息已失效，此操作需要您重新授权后才能进行',
// 				content: err.Info,
// 				success: function (res) {
// 					if (res.confirm) {
// 						wxx.NavigateTo({
// 							url: appObj.AuthPage,
// 							data: {
// 								opentype: opentype,
// 							},
// 						});
// 					}
// 				},
// 			});
// 		}
// 	};
// }

// app.ForceUpdate = function () {
// 	var updateManager = wx.getUpdateManager();
// 	updateManager.onCheckForUpdate(function (res) {
// 		if (res.hasUpdate) {
// 			wx.showLoading({
// 				title: '发现新版本，下载中...',
// 				mask: true,
// 			});
// 		}
// 	});
// 	updateManager.onUpdateReady(function () {
// 		// wx.showModal({
// 		//     title: '更新提示',
// 		//     content: '新版本已经准备好，是否重启应用？',
// 		//     success: function (res) {
// 		//         if (res.confirm) {
// 		//             updateManager.applyUpdate();
// 		//         }
// 		//     },
// 		// });
// 		wx.hideLoading();
// 		wx.showModal({
// 			title: '更新提示',
// 			content: '新版本已经准备好，准备重启小程序',
// 			showCancel: false,
// 			complete: function () {
// 				updateManager.applyUpdate();
// 			},
// 		});
// 	});
// 	updateManager.onUpdateFailed(function () {
// 		wx.showModal({
// 			title: '更新提示',
// 			content: '新版本下载失败',
// 			showCancel: false,
// 		});
// 	});
// };

// app.Highlight = function (text, keywordRegExp, keywordClass, keywordEvent) {
// 	if (!text || !keywordRegExp) {
// 		return null;
// 	}
// 	var textArray = [];
// 	var result = keywordRegExp.exec(text);
// 	while (result) {
// 		var nonKeyword = text.substring(0, result.index);
// 		var keyword = result[0];
// 		text = text.substr(result.index + keyword.length);
// 		textArray.push({
// 			IsKeyword: false,
// 			Text: nonKeyword,
// 		});
// 		textArray.push({
// 			IsKeyword: true,
// 			Text: keyword,
// 			Class: keywordClass,
// 			Event: keywordEvent,
// 		});
// 		result = keywordRegExp.exec(text);
// 	}
// 	if (text) {
// 		textArray.push({
// 			IsKeyword: false,
// 			Text: text,
// 		});
// 	}
// 	if (!textArray.length) {
// 		return null;
// 	}
// 	return textArray;
// };

// var regexp = {};
// regexp.ContainsMobilePhone = /0?(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[89])[0-9]{8}/;
// regexp.ContainsTelephone = /0\d{2}-\d{7,8}/;
// regexp.ContainsPhone = /(0?(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[89])[0-9]{8})|(0\d{2,3}-\d{7,8})/;

// // ---------------------------------------------------------------------------
// // UI相关，需要配合相关模板使用
var ui = require("./jw.ui.js");
// // var radio = require('./radio/radio.js');
// // var checkbox = require('./checkbox/checkbox.js');
// var scrollview = require('./scroll-view/scroll-view-utils.js');
// var list = require('./list/list-utils.js');
// // var imageInputUtils = require('./image-input/image-input-utils.js');
// // var customTabBar = require('./custom-tabbar/custom-tabbar.js');

// var pay = require('./jw.pay.js');

// // var modal = require('./modal/modal-utils.js');
// // wxx.showModal = modal.ShowModal;

// // var tutorial = require('./tutorial/tutorial-utils.js');

// module.exports = {
// 	strings: strings,
// 	json: json,
// 	utils: utils,
// 	time: time,
// 	// dom: dom,
// 	net: net,
// 	// wxx: wxx,
// 	// app: app,
// 	// regexp: regexp,
// 	// ui: ui,
// 	// radio: radio,
// 	// checkbox: checkbox,
// 	// scrollview: scrollview,
// 	// list: list,
// 	// imageInputUtils: imageInputUtils,
// 	// customTabBar: customTabBar,
// 	// pay: pay,
// 	// modal: modal,
// 	// tutorial: tutorial,
// };
export default {
  data: data,
  strings: strings,
  json: json,
  utils: utils,
  time: time,
  // dom: dom,
  net: net,
  // wxx: wxx,
  // app: app,
  // regexp: regexp,
  ui: ui,
  // radio: radio,
  // checkbox: checkbox,
  // scrollview: scrollview,
  // list: list,
  // imageInputUtils: imageInputUtils,
  // customTabBar: customTabBar,
  // pay: pay,
  // modal: modal,
  // tutorial: tutorial,
};
