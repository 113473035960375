<template>
  <v-container>
    <v-row align="center">
      <v-col cols="1">
        <v-btn
          fab
          small
          color="blue darken-1"
          class="white--text"
          @click="$refs.calendar.prev()"
        >
          <v-icon dark>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-sheet height="600px">
          <v-calendar
            ref="calendar"
            v-model="calendar.Start"
            type="month"
            :weekdays="[0, 1, 2, 3, 4, 5, 6]"
            short-intervals
            short-months
            short-weekdays
            color="primary"
            event-overlap-mode="stack"
            :events="calendar.Events ? calendar.Events : []"
            :event-color="getCalendarEventColor"
            @change="getCalendarEvents"
            @click:date="onDateClick"
          ></v-calendar>
        </v-sheet>
      </v-col>
      <v-col cols="1">
        <v-btn
          fab
          small
          color="blue darken-1"
          class="white--text"
          @click="$refs.calendar.next()"
        >
          <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jwa from "./jw.aman";

export default {
  name: "APanelCalendar",

  props: {
    panel: {
      type: Object,
    },
  },

  data() {
    return {
      calendar: {
        Start: new Date().toISOString().substr(0, 10),
        Events: [],
        DateTap: {},
        EventStart: null,
        EventEnd: null,
      },
    };
  },

  methods: {
    getCalendarEvents({ start, end }) {
      var api = this.panel.ContentApi;
      if (!api) {
        return;
      }
      var client = this.$store.state.client;
      var that = this;
      client
        .Post(api, {
          data: {
            Key: {
              Start: start.date,
              End: end.date,
            },
          },
        })
        .then(function(res) {
          if (!res) {
            return;
          }
          that.calendar.Events = res.Events;
          that.calendar.DateTap = res.DateTap;
          that.calendar.EventStart = start;
          that.calendar.EventEnd = end;
        })
        .catch(function(err) {
          that.$store.ShowError(err.Info);
        });
    },
    getCalendarEventColor(event) {
      return event.color;
    },
    onDateClick({ date }) {
      // console.log(date);
      var dateTap = this.calendar.DateTap;
      dateTap.Key = {
        Date: date,
      };
      this.doButton(dateTap);
    },

    doButton(button) {
      if (!button) {
        return;
      }
      var action = button.Action;
      var client = this.$store.state.client;
      var that = this;
      var api = button.Api;
      var key = button.Key;
      switch (action) {
        case jwa.PanelButtonAction.Api: {
          if (api) {
            client
              .Post(api, {
                data: {
                  Key: key,
                },
              })
              .then(function() {
                that.changeContentPage(that.page);
              })
              .catch(function(err) {
                that.$store.ShowError(err.Info);
              });
          }
          break;
        }
        case jwa.PanelButtonAction.Form: {
          if (api) {
            client
              .Post(api, {
                data: {
                  Key: key,
                },
              })
              .then(function(res) {
                if (!res) {
                  return;
                }
                that.form = res;
                that.submit = button.Submit;
                that.showForm = true;

                that.$emit("form", {
                  form: res,
                  submit: button.Submit,
                  showForm: true,
                });
              })
              .catch(function(err) {
                that.$store.ShowError(err.Info);
              });
          }
          break;
        }
        default: {
          // TODO
          break;
        }
      }
    },
  },
};
</script>
